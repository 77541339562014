import React from 'react'
import styled from 'styled-components'
import SvgEn from './assets/en.svg'
import SvgCn from './assets/cn.svg'

import i18n from 'i18next'

const Lang: React.FC = () => {
  function langChange() {
    const lang = i18n.language === "en" ? "zh_cn" : "en"
    i18n.changeLanguage(lang)
  }
  return (
    <LangIconContainer onClick={langChange}>
      <img src={i18n.language === "en" ? SvgEn : SvgCn} alt={'i18n'} />
    </LangIconContainer>

  )
}
const LangIconContainer = styled.div`
  width: 25px;
  height: 25px;
  background: rgba(0,0,0,0);
  img {
    width: 100%;
    height: 100%;
  }



`
export default Lang