import { Reducer } from "redux";
import { produce } from "immer";
import BigNumber from "bignumber.js";

const ActionEnum = {
    UpdatePoolData: "[pool]UpdatePoolData",
};



export const ActUpdatePoolData = (data: { [key: string]: Partial<PoolData> }) => ({
    type: ActionEnum.UpdatePoolData,
    data,
});

type PoolData = {
    earned1: BigNumber,
    earned2?: BigNumber,
    staked1: BigNumber,
    staked2?: BigNumber,
    apy?: string
    total?: BigNumber,
    maxBalance?: BigNumber
    nav?:BigNumber
}

const defaultPoolData: PoolData = {
    earned1: new BigNumber(0),
    earned2: new BigNumber(0),
    staked1: new BigNumber(0),
    staked2: new BigNumber(0),
}

interface IPoolState {
    [pid: string]: PoolData
}

const init: IPoolState = {
};

export type TAction = ReturnType<typeof ActUpdatePoolData>;

export const PoolReducer: Reducer<IPoolState, TAction> = (
    state = init,
    action
) =>
    produce(state, (draft) => {
        switch (action.type) {
            case ActionEnum.UpdatePoolData:
                const data = action.data
                for (const pid in action.data) {
                    if (!draft[pid]) {
                        draft[pid] = {
                            ...defaultPoolData,
                            ...data[pid],
                        }
                    } else {
                        draft[pid] = {
                            ...draft[pid],
                            ...data[pid],
                        }
                    }
                }
                return;
        }
    });
