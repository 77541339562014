import { EDCServiceSet, PoolsConfig, PoolsRequiredConfig } from "./types"

const service: EDCServiceSet = {
    earned1: { method: "earned", },
    staked1: { method: "balanceOf", },
    deposit: { method: "stake", },
    withdraw: { method: "withdraw", },
    exit: { method: "exit", },
    harvest: { method: "getReward", },
    apy: {
        method: "",
        rest: { endpoint: "/api/apy/" }
    }
}
const cfg: PoolsRequiredConfig[] = [
    {
        poolAddresses: {
            128: "0x917D7FcccB175FEBa33F373d2A5e87860f88D928",
        },
        stakingTokenAddresses: {
            128: "0x0000000000000000000000000000000000000000",
        },
        name: "HT ",
        symbol: "HT ",
        tokenSymbol: "HT ",
        isWBNB: true,
        icon: "ht",
        decimal: 18,
        magnification: 4,
        earnToken: "EDC",
        earnDecimal: 9,
    },
    {
        poolAddresses: {
            128: "0xE3237dF08aC00B215801817682e1CC69E54BD15f",
        },
        stakingTokenAddresses: {
            128: "0x66a79D23E58475D2738179Ca52cd0b41d73f0BEa",
        },
        name: "BTC ",
        symbol: "BTC ",
        tokenSymbol: "BTC ",
        icon: "hbtc",
        decimal: 18,
        magnification: 4,
        earnToken: "EDC",
        earnDecimal: 9,
    },
    {
        poolAddresses: {
            128: "0xd9764a8b7D7e98e64849Dc0eAc69845E51d8e869",
        },
        stakingTokenAddresses: {
            128: "0x64FF637fB478863B7468bc97D30a5bF3A428a1fD",
        },
        name: "ETH ",
        symbol: "ETH ",
        tokenSymbol: "ETH ",
        icon: "eth",
        decimal: 18,
        magnification: 4,
        earnToken: "EDC",
        earnDecimal: 9,
    },
    {
        poolAddresses: {
            128: "0x9B0ae0610e49dE04Cde0cdc909B908c266FD05d5",
        },
        stakingTokenAddresses: {
            128: "0xa71EdC38d189767582C38A3145b5873052c3e47a",
        },
        name: "USDT ",
        symbol: "USDT ",
        tokenSymbol: "USDT ",
        icon: "usdt",
        decimal: 18,
        magnification: 4,
        earnToken: "EDC",
        earnDecimal: 9,
    },
    {
        poolAddresses: {
            128: "0x577ab98Dc9aB39D792d059F35A30E0ccDfe8a25b",
        },
        stakingTokenAddresses: {
            128: "0x0298c2b32eaE4da002a15f36fdf7615BEa3DA047",
        },
        name: "HUSD ",
        symbol: "HUSD ",
        tokenSymbol: "HUSD ",
        icon: "husd",
        decimal: 18,
        magnification: 4,
        earnToken: "EDC",
        earnDecimal: 9,
    },
    {
        poolAddresses: {
            128: "0x93c509afC9Ef33b70def1410e9d3207f17E56363",
        },
        stakingTokenAddresses: {
            128: "0xE499Ef4616993730CEd0f31FA2703B92B50bB536",
        },
        name: "HPT ",
        symbol: "HPT ",
        tokenSymbol: "HPT ",
        icon: "hpt",
        decimal: 18,
        magnification: 4,
        earnToken: "EDC",
        earnDecimal: 9,
    },
    {
        poolAddresses: {
            128: "0xA50f81b68F40C4f2540E6aC3A27273953A31832B",
        },
        stakingTokenAddresses: {
            128: "0xA2c49cEe16a5E5bDEFDe931107dc1fae9f7773E3",
        },
        name: "DOT ",
        symbol: "DOT ",
        tokenSymbol: "DOT ",
        icon: "dot",
        decimal: 18,
        magnification: 4,
        earnToken: "EDC",
        earnDecimal: 9,
    }

]

const vault2Pool = cfg.map<PoolsConfig>((e) => ({
    ...e,
    page: "vault2",
    symbol: e.name,
    tokenSymbol: e.name,
    decimal: e.decimal ?? 18,
    isWBNB: e.isWBNB ?? false,
    magnification: e.magnification ?? 4,
    earnToken: e.earnToken ?? "HUSD",
    earnDecimal: e.earnDecimal ?? 8,
    apyName: "husdht",
    service,
}))

export default vault2Pool