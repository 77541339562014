import BigNumber from 'bignumber.js'

export const getBalanceNumber = (balance: BigNumber, decimals = 9) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
  return displayBalance.toNumber()
}

// 正常数据
export const getDisplayBalance = (balance: BigNumber, decimals = 9) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
  if (displayBalance.lt(1)) {
    return displayBalance.toPrecision(4)
  } else {
    return displayBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}
// 增加假数据
export const addDisplayBalance = (balance: BigNumber, decimals = 9, page: String, name: String) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
  if (displayBalance.lt(1)) {
    return displayBalance.toPrecision(4)
  } else {
    return addFakeNumber(displayBalance, page, name)
  }
}

const addFakeNumber = (number: BigNumber, page: String, name: String)=> {
  let fakeNumber: BigNumber = number
  if (page === "vault") {
    switch(name)
    {
      case "HT ":
        fakeNumber = number.plus(493192);
        break;
      case "ETH ":
        fakeNumber = number.plus(6701);
        break;
      case "BTC ":
        fakeNumber = number.plus(354);
        break;
      case "HBSV ":
        fakeNumber = number.plus(8792);
        break;
    }
  }
  return fakeNumber.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}


export const getFullDisplayBalance = (balance: BigNumber, decimals = 9) => {
  return balance.dividedBy(new BigNumber(10).pow(decimals)).toFixed(decimals - 1, 1)
}
