import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useWallet } from "use-wallet";
import { ActSetState } from "./state/global";
import { useTypedSelector } from "./state/store";

const KEY = "META_MASK_CONNECT";

export function canDirectlyConnectMetaMask() {
  // return can;
  return true;
}

export function connectToMetaMask() {
  localStorage.setItem(KEY, "1");
}
export function connectMetaMaskReset() {
  localStorage.removeItem(KEY);
}

async function shouldShowComingSoon(): Promise<boolean> {
  try {
    const res = await axios.get("https://api.earndefi.finance/1", {
      timeout: 200,
    });
    return res.data === "1";
  } catch {
    return false;
  }
}

export function useAutoConnect() {
  const { connect, status } = useWallet();
  const showConnect = useTypedSelector((e) => e.GlobalReducers.showConnect);

  if (status === "error") {
    connectMetaMaskReset();
  }

  shouldShowComingSoon().then((res) => {
    if (res) {
      dispatch(ActSetState({ showClaim: true }));
    }
  });

  //manually disconnect from metamask
  if (showConnect === true && status === "disconnected") {
    connectMetaMaskReset();
  }
  const dispatch = useDispatch();
  useEffect(() => {
    const showConnect = () => {
      dispatch(ActSetState({ showConnect: true }));
    };

    if (canDirectlyConnectMetaMask() && status === "disconnected")
      connect("injected").then(showConnect).catch(showConnect);
    else showConnect();
  }, [connect,status]);
}
