import React from "react";
import useModal from "../../hooks/useModal";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import WalletProviderModal from "../WalletProviderModal";
import { useTypedSelector } from "../../libs/state/store";

export default function ConnectButton() {
  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />);
  const { t } = useTranslation();
  const showConnect = useTypedSelector((e) => e.GlobalReducers.showConnect);
  if (!showConnect) return null;
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flex: 1,
        justifyContent: "center",
      }}
    >
      <Button
        onClick={onPresentWalletProviderModal}
        text={t("unlockWalletEmoji")}
      />
    </div>
  );
}
