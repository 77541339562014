import React from 'react'
import styled from 'styled-components'
import Audit1 from "../../assets/img/Audit1.png"
import Audit2 from "../../assets/img/Audit2.png"
import Audit3 from "../../assets/img/Audit3.png"
import { useTranslation } from "react-i18next"
import { message } from 'antd';

const Audit: React.FC = () => {
  const { t } = useTranslation()
  return (
    <StyledAudit className="center-center">
      <StyledTitle>{t("AuditTitle")}</StyledTitle>
      <StyledContent className="center-center">
        <StyledBox target="https://t.me/earndefien" href="/audit.pdf" className="center-center"><img src={Audit1} /></StyledBox>
        <StyledBox target="https://t.me/earndefien" href="/certik.pdf"   className="center-center"><img src={Audit2} /></StyledBox>
        <StyledBox onClick={() => {
            message.info('Coming Soon');
          }} className="center-center"><img src={Audit3} /></StyledBox>
      </StyledContent>
    </StyledAudit>
  )
}

const StyledAudit = styled.div`
  background: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 40px 40px 30px;
  displsy: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 30px;

  @media (min-width: 768px) {
    width: 1150px;
  }
  @media (max-width: 768px) {
    width: 1150px;
  }
`

const StyledTitle = styled.div`
  font-family: Noto Sans SC;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.02em;
  color: #FDF4D7;
  text-align: center;
`
const StyledContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  flex-wrap: wrap;
  width: 100%;
`
const StyledBox = styled.a`
  margin: 0 12px;
  width: 272px;
  height: 82px;
  background: rgba(63, 63, 63, 0.5);
  backdrop-filter: blur(50px);
  border-radius: 16px;
  margin-bottom: 10px;
`
export default Audit