import React from "react";
import styled from "styled-components";
// import Container from '../../components/Container'
import Page from "../../components/Page";
import Audit from "../../components/Audit";
// import Balances from './components/Balances'
import { useTranslation } from "react-i18next";
import IconJiaoYiSuo from "../../assets/img/homes/jiaoyisuo.png";
import IconJiQiangChi from "../../assets/img/homes/jiqiangchi.png";
import IconKanBan from "../../assets/img/homes/kanban.png";
import IconKuaLianQiao from "../../assets/img/homes/kualianqiao.png";
import IconLiangHua from "../../assets/img/homes/lianghua.png";
import IconZhiYa from "../../assets/img/homes/zhiya.png";


const Home: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Page>
      <StyledContainer>
        {/* <DataBoard /> */}
        <StyledWrapperBox>
          <StyledItemBox>
            <div>
              <img width="80px" height="80px" src={IconJiQiangChi}  alt={"icon"}/>
            </div>
            <p>{t("infoName3")}</p>
            <p>{t("infoText3")}</p>
          </StyledItemBox>
          <StyledItemBox>
            <div>
              <img width="80px" height="80px" src={IconZhiYa} alt={"icon"}/>
            </div>
            <p>{t("infoName1")}</p>
            <p>{t("infoText1")}</p>
          </StyledItemBox>
          <StyledItemBox>
            <div>
              <img width="80px" height="80px" src={IconLiangHua} alt={"icon"}/>
            </div>
            <p>{t("infoName2")}</p>
            <p>{t("infoText2")}</p>
          </StyledItemBox>
        </StyledWrapperBox>
        <StyledWrapperBox>
          <StyledItemBox>
            <div>
              <img width="80px" height="80px" src={IconJiaoYiSuo} alt={"icon"}/>
            </div>
            <p>{t("infoName4")}</p>
            <p>{t("infoText4")}</p>
          </StyledItemBox>
          <StyledItemBox>
            <div>
              <img width="80px" height="80px" src={IconKanBan} alt={"icon"}/>
            </div>
            <p>{t("infoName5")}</p>
            <p>{t("infoText5")}</p>
          </StyledItemBox>
          <StyledItemBox>
            <div>
              <img width="80px" height="80px" src={IconKuaLianQiao} alt={"icon"}/>
            </div>
            <p>{t("infoName6")}</p>
            <p>{t("infoText6")}</p>
          </StyledItemBox>
        </StyledWrapperBox>
        <Audit />
      </StyledContainer>
    </Page>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: calc( 70px + 48px + 16px );
  @media (max-width: 768px) {
    margin-top: 90px;
  }
`;
const StyledWrapperBox = styled.div`
  width: 100%;
  /* margin-top: 20px; */
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    display: block;
  }
`;

const StyledItemBox = styled.div`
  width: 360px;
  height: 330px;
  display: inline-block;
  text-align: center;
  border-radius: 1px;
  margin: 6px 18px;
  float: left;
  padding: 60px 40px 0;
  box-sizing: border-box;
  background: rgba(0,0,0,0.26);
  > div {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    display: inline-block;
    margin-bottom: 20px;
  }
  > p {
    color: #FFF5D3;
    font-size: 20px;
    max-width: 310px;
  }
  > p:last-child {
    font-size: 13px;
    color: #999999;
    text-align: left;
    letter-spacing: 0.5px;
    text-align: justify;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 10px 0px;
  }
`;

export default Home;
