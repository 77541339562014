export const Accelerators = [
    {
        addresses: {
            97: '0x7377ED43F5A79cc89A5cE5D6632764a3494996B7'
        },
        poolAddresses: {
            97: '0xB9eEe48F597c551843FcC099f90013895B9c7263'
        },
        symbol: 'TEST'
    }
]