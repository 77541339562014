import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import PoolTotalAbi from '../constants/abi/PoolTotal.json'

export const getContract = (address: string) => {
  const web3 = new Web3(Web3.givenProvider)
  return new web3.eth.Contract(
    PoolTotalAbi as unknown as AbiItem,
    address,
  )
}

export async function getPoolTotal(pool_addr: string) {
  const contract = getContract(pool_addr)
  const getTvl = await contract.methods.getTvl().call();
  return getTvl
}
