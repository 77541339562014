import React from 'react'
import styled from 'styled-components'
import Telegram from '../../../assets/img/footer_icons/telegram.svg';
import Twitter from '../../../assets/img/footer_icons/twitter.svg';
import Medium from '../../../assets/img/footer_icons/medium.svg';
import Audit from '../../../assets/img/footer_icons/anquan.svg';
import Docs from '../../../assets/img/footer_icons/book.svg';

const StyledNav = styled.nav`
align-items: center;
display: flex;
justify-content: space-between;
flex-wrap: wrap;
max-width: 200px;
width:100%;
margin: 8px;
text-align: center;
@media(max-width: 540px) {
  justify-content: space-around;
}`

const StyledLink = styled.a`
  color: ${(props) => props.theme.color.yellow};
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 34px;
  &:hover {
    color: ${(props) => props.theme.color.yellow};
  }
  @media(max-width: 540px) {
    /* padding-left: ${(props) => props.theme.spacing[3]}px; */
    padding-right: ${(props) => props.theme.spacing[2]}px;
    padding-left: ${(props) => props.theme.spacing[2]}px;
  }
`
const Nav: React.FC = () => {
  return (
    <StyledNav>
      <StyledLink target="https://t.me/earndefien" href="https://t.me/earndefiheco">
        <img width="26px" height="26px" src={Telegram} alt="Telegram" />
      </StyledLink>
      <StyledLink target="https://twitter.com/EarnDefiCoin" href="https://twitter.com/EarnDefiCoin">
        <img width="22px" height="22px" src={Twitter} alt="Twitter" />
      </StyledLink>
      <StyledLink target="https://medium.com/@EarnDefiCoin" href="https://medium.com/@EarnDefiCoin">
        <img width="22px" height="22px" src={Medium} alt="Medium" />
      </StyledLink>
      <StyledLink target="https://t.me/earndefien" href="/audit.pdf">
        <img width="26px" height="26px" src={Audit} alt="Audit" />
      </StyledLink>
      <StyledLink target="https://twitter.com/EarnDefiCoin" href="https://www.yuque.com/docs/share/63cb48ca-c691-4396-bb21-1b28643557f3?#%20%E3%80%8A%E4%B8%80%E6%96%87%E8%AF%BB%E6%87%82EarnDefi%EF%BC%88%E7%81%AB%E5%B8%81%E7%94%9F%E6%80%81%E9%93%BE%EF%BC%89%E3%80%8B">
        <img width="23px" height="23px" src={Docs} alt="Docs" />
      </StyledLink>
    </StyledNav>
  )
}

export default Nav
