import BigNumber from "bignumber.js";
import { useCallback, useMemo } from "react"
import { useWallet } from "use-wallet";
import { provider, TransactionReceipt } from "web3-core";
import { EDCSvcKeyChoices } from "../constants/pools/types"
import { Farm } from "../contexts/Farms"
import { isV3Pool } from "../contexts/Farms/types"
import { getPoolContractByFarm } from "../utils/pool"
import { Contract, ContractSendMethod } from 'web3-eth-contract'
import { ActSetState } from "../libs/state/global";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import React from "react";
type CallOpts = {
    contract: Contract,
    account: string,
    method: string,
    parameters: any[],
    send_param?: any

    onHash?: (hash: string) => void
    onConfirm?: (r: TransactionReceipt) => void
    onError?: () => void
    

}
const LookAtHecoInfo:React.FC<{txhash:string}> = ({txhash})=>(
    <div>
        在 <a target="_blank" href={`https://hecoinfo.com/tx/${txhash}`} rel="noopener noreferrer" > HecoInfo</a> 上查看.
    </div>
)

async function SendContractMethods(opts: CallOpts) {
    const { contract, method, parameters, account, onHash, onConfirm, onError,send_param } = opts
    return new Promise<any>(async (resolve, reject) => {
        try {
            console.log(`general contract send ${opts.method}`)
            const tm: ContractSendMethod = contract
                .methods[method](...parameters)
            const res = await
                tm.send({ from: account, gas: 3000000 ,...send_param})
                    .on("transactionHash", (e) => {
                        onHash && onHash(e)
                        notification['info']({
                            message:"提交成功",
                            description: <LookAtHecoInfo txhash={e}/>
                        })
                    })
                    .on("confirmation", (t, r) => {
                        if(t===0){
                            onConfirm && onConfirm(r)
                            notification['success']({
                                message:"交易已经确认",
                                description:  <LookAtHecoInfo txhash={r.transactionHash}/>
                            })
                        }
                    })
                    .on('error', (e) => {
                        console.log('general send error', e)
                        onError && onError()
                    })
            console.log(`finished general contract send ${opts.method} (${res})`)
            resolve(res)
        } catch (e) {
            onError && onError()
            console.log(`error general contract send ${opts.method} (${e})`)
            reject(e)
        }
    })
}

type ActionCtx = {
    farm: Farm,
    userParam: any[],
    action: EDCSvcKeyChoices
    method: string
}


function getActionParam({ farm, userParam, action, method }: ActionCtx) {
    if (action === "deposit" && method === "stake"){
        if(farm.isWBNB)
            return []
        return [...userParam]
    }
    if (action === "deposit" && method === "deposit")
        return [farm.contract_pid, ...userParam]

    if (action === "withdraw") {
        if (method === "getReward")
            return []
        if (method === "withdraw" && isV3Pool(farm))
            return [farm.contract_pid, ...userParam]
        return [...userParam]
    }

    if (action === "exit") {
        if (isV3Pool(farm))
            return [farm.contract_pid]
        return []
    }

    if (action === "harvest") {
        if (isV3Pool(farm))
            return [farm.contract_pid, 0]
        return []
    }


}

export function useCardAction(farm: Farm) {

    const { ethereum, account } = useWallet();
    const dispatch = useDispatch()

    const contract = useMemo(() => {
        return getPoolContractByFarm({
            provider: ethereum as provider,
            farm,
        })
    }, [farm, ethereum])

    const onHOAction = useCallback(async (action: EDCSvcKeyChoices, userParam: any[],send_param?:any) => {
        const method = farm.service[action].method
        dispatch(ActSetState({ loadingHecoTX: true }));
        await SendContractMethods({
            account, contract, method,
            parameters: getActionParam({ farm, method, userParam, action }),
            send_param,
            onHash: (e) => console.log("new tx hash", e),
            onConfirm: (r) => {
                dispatch(ActSetState({ loadingHecoTX: false }))
                return console.log("new tx confirm", r);
            },
            onError: () => dispatch(ActSetState({ loadingHecoTX: false }))
        })
    }, [account, contract, farm, dispatch])

    const onDeposit = useCallback((value: BigNumber,isWBNB?:boolean) => {
        const action: EDCSvcKeyChoices = 'deposit'
        if(isWBNB)
            onHOAction(action, [],{value})
        onHOAction(action, [value.toFixed()])
    }, [onHOAction])

    const onHarvest = useCallback(() => {
        const action: EDCSvcKeyChoices = 'harvest'
        onHOAction(action, [])
    }, [onHOAction])

    const onWithdraw = useCallback((value: BigNumber) => {
        const action: EDCSvcKeyChoices = 'withdraw'
        onHOAction(action, [value.toFixed(0,1)])
    }, [onHOAction])

    const onExit = useCallback(() => {
        const action: EDCSvcKeyChoices = 'exit'
        onHOAction(action, [])
    }, [onHOAction])


    return {
        onDeposit,
        onHarvest,
        onWithdraw,
        onExit
    }

}