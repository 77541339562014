import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import TextScroll from "../TestScroll";
// import Note from "../Note";

import styled from "styled-components";

import SvgTexture from "./texture.svg";

import SvgTitle from "./title.svg";

export default function ScrollInfoBar() {
  const [msg, setMsg] = useState("");

  // const rollerRef = useRef<null | HTMLDivElement>(null);

  const rootRef = useRef<null | HTMLDivElement>(null);

  //数据库中请求公告
  useEffect(() => {
    axios
      .get("https://api.earndefi.finance/notice")
      .then((e) => setMsg(e.data.text));
  }, []);

  // useEffect(() => {
  //   if (rollerRef.current && rootRef.current) {
  //   }
  //   return () => { };
  // }, [rollerRef, rootRef, msg]);

  if (msg.length === 0) {
    return null;
  }

  return (
    <>
      <Root bg={SvgTexture} ref={rootRef}>
        <Marq>
          <Title>
            <img src={SvgTitle} style={{ paddingRight: "4px" }} alt={"icon"}></img>
            <span>公告:</span>
          </Title>
          <Tbox>
            <TextScroll content={msg} />
          </Tbox>
          <Tmore><a href='/more' target="__blank" >更多 &gt; </a></Tmore>
        </Marq>
      </Root>
    </>
  );
}


const Title = styled.div`
  flex-shrink: 1;
  justify-content: space-around;
  img {
    width: 18px;
    height: auto;
  }

  span {
    white-space: no-wrap;
  }
  font-family: Source Han Sans CN;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: justify;
  letter-spacing: 1.4px;
  color: #edb605;
  display: inline-flex;
  align-items: center;
  /////////////////////////
  width:90px;
  @media screen and (max-width:500px) {
    margin-left:10px;
    width:70px;
  }
`;

const Root = styled.div<{ bg: string }>`
  
    background: url(${(props) => props.bg}) center no-repeat;
    
  @media screen and (min-width:1024px) {
      height:45px;
      /* display:flex; */
      /* flex-direction:row; */
      /* align-items:flex-start; */
       /* margin-left:26%; */
       width:100%;
       margin:0 auto;
      position:fixed;
      background-color:#22242A;
      z-index:1;
  }


  @media screen and (max-width:960px) and (min-width:500px) {
      position:fixed;
      background-color:#171A24;
      z-index:1;

  }


`;


const Marq = styled.div`
      width:900px;
      margin:0 auto;
      height:100%;
      display:flex;
      flex-direction:row;
      justify-content:flex-start;
       
`

const Tbox = styled.div`
color:#909090;
  @media screen and (min-width:1024px) {
       width:745px;
      padding-left:10px;
  }
  
       @media screen and (min-width:500px) and (max-width:1000px) {
         width:610px;
       }
`

const Tmore = styled.div`
        width:60px;
        height:40px;
        line-height: 40px;
        padding-left: 10px;
        position:relative;
        right:10px;
        /* border:1px solid #ff0; */
        a{
          color:#fff;
          text-decoration:none;
          font-size:13px;
        }
        //先去掉-2021.2.5
        display:none
`
