import React, { useEffect, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useWallet } from "use-wallet";

import Page from "../../components/Page";
import PageHeader from "./components/PageHeader";

import Farm from "../Farm";

import FarmCards from "./components/FarmCards";
import axios from "axios";
import { FarmPageChoices } from "../../contexts/Farms/types";
import ConnectButton from "../../components/ConnectButton";
import PoolSwitch from "../../components/PoolSwitch";
import Creator from "../../components/Creator";
import { getPoolTotal } from "../../utils/poolTotal";
import { EDCPOOLTOTAL } from "../../constants/addresses";

interface FarmsId {
  page: FarmPageChoices;
}
const Farms: React.FC<FarmsId> = ({ page }) => {
  const { path } = useRouteMatch();
  const { account } = useWallet();

  const [, setPrice] = useState("$ 0");
  const [total, setTotal] = useState("$ 0");
  const [display, setDisplay] = useState("all")
  let totalNumber = 0 

  const getRandomMath = ()=> {
    let numberRandom = Math.floor(Math.random()*100)
    return (numberRandom.toString().length > 1 ? numberRandom : `0${numberRandom.toString()}`)
  }

  const getTvl = ()=> {
    getPoolTotal(EDCPOOLTOTAL).then((res)=> {
      if (totalNumber !== res) {
        totalNumber = res
        setTotal(`$ ${res}.${getRandomMath()}`);
      }
    })
  }
  useEffect(() => {
    setDisplay('active')
  }, [])
  useEffect(() => {
    // axios.get("https://api.earndefi.finance/price/edc/").then((res: any) => {
    //   setPrice(res.data);
    // });
    getTvl()
    let lpTime = setInterval(()=>{
      getTvl()
    },10000)
    return ()=> {
      clearInterval(lpTime)
    }
  },[]);

  return (
    <Switch>
      <Page>
        <Creator/>
        {account ? (
          <>
            <Route exact path={path}>
              {
                true ? <PoolSwitch TVL={`${total}`} value={display} onChange={e => setDisplay(e)} /> :
                  <PageHeader title="Total Value Locked" subtitle={`${total}`} />
              }
              <FarmCards page={page} display={display} />
            </Route>
            <Route path={`${path}/:farmId`}>
              <Farm />
            </Route>
          </>
        ) : (
          <ConnectButton />
        )}
      </Page>
    </Switch>
  );
};

export default Farms;
