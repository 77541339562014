import React from "react";

import { useWallet } from "use-wallet";
import { Pools } from "../../constants/pools";
import { PoolsConfig } from "../../constants/pools/types";

// import { bnToDec } from '../../utils'

import Context from "./context";
import { Farm } from "./types";

const Farms: React.FC = ({ children }) => {
  const { chainId } = useWallet();

  const farms: Array<Farm> = Pools.filter(
    (pool) => (pool.poolAddresses as any)[chainId]
  ).map(
    (
      {
        earnDecimal,
        poolAddresses,
        name,
        symbol,
        tokenSymbol,
        stakingTokenAddresses,
        isWBNB,
        icon,
        magnification,
        decimal,
        apyName,
        page,
        earnToken,
        service,
        contract_pid,
      }: PoolsConfig,
      index
    ) => ({
      page,
      pid: index,
      id: symbol.replace("/", "-") + index.toString(),
      name,
      poolAddress: poolAddresses[chainId],
      stakingToken: symbol,
      stakingTokenAddress: stakingTokenAddresses[chainId],
      tokenSymbol,
      earnToken,
      earnDecimal,
      earnTokenAddress: "0x68a0a1fef18dfcc422db8be6f0f486dea1999edc",
      isWBNB,
      icon,
      magnification,
      decimal,
      apyName,
      service,
      contract_pid: contract_pid ?? 0,
    })
  );

  return (
    <Context.Provider
      value={{
        farms,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Farms;
