import BigNumber from "bignumber.js";
import React, { useEffect, useState } from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";
import styled, { keyframes } from "styled-components";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import CardContent from "../../../components/CardContent";
import { Farm } from "../../../contexts/Farms";
import { useTranslation } from "react-i18next";
import DepositImg from '../../../assets/img/deposit.svg'
import HarvestImg from '../../../assets/img/harvest.svg'
import APYImg from '../../../assets/img/APY.svg'
import { useCardInfoUpdater } from "../../../hooks/useCardInfo";
import { useTypedSelector } from "../../../libs/state/store";
import { addDisplayBalance } from "../../../utils/formatBalance";
import { Progress, Spin } from "antd";
import { isV3Pool } from "../../../contexts/Farms/types";


const renderer = (countdownProps: CountdownRenderProps) => {
    const { hours, minutes, seconds } = countdownProps;
    const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const paddedHours = hours < 10 ? `0${hours}` : hours;
    return (
        <span style={{ width: "100%" }}>
            {paddedHours}:{paddedMinutes}:{paddedSeconds}
        </span>
    );
};

interface FarmCardProps {
    farm: Farm,
    display?: string
}

type ProgressCtx = {
    max: BigNumber,
    cur: BigNumber,
    prog: string
    loading?: boolean

}
const FarmCard: React.FC<FarmCardProps> = ({ display, farm }) => {
    const [imagePath, setImagePath] = useState("");
    const [progressCtx, setProgressCtx] = useState<ProgressCtx | undefined>(undefined)
    useCardInfoUpdater(farm)
    const apy = useTypedSelector(e => e.PoolReducer[farm.pid]?.apy ?? "Loading ...")
    const apr: any = undefined as any
    const totalStake = useTypedSelector(e => e.PoolReducer[farm.pid]?.total ?? new BigNumber(0))
    const userStake = useTypedSelector(e => e.PoolReducer[farm.pid]?.staked1 ?? new BigNumber(0))
    const maxBalance = useTypedSelector(e => e.PoolReducer[farm.pid]?.maxBalance ?? new BigNumber(0))
    const stakedOnly = useTypedSelector(e => e.GlobalReducers.stakedOnly)
    const { t } = useTranslation();
    useEffect(() => {
        if (isV3Pool(farm)) {
            setProgressCtx({
                max: new BigNumber(0),
                cur: new BigNumber(0),
                prog: "0",
                loading: true
            })
        }
    }, [farm])
    useEffect(() => {
        if (!maxBalance.isZero()) {

            const prog = totalStake
                .dividedBy(maxBalance)
                .multipliedBy(100)
                .toFixed(2)

            setProgressCtx({
                max: maxBalance,
                cur: totalStake,
                prog,
                loading: false
            })
        }
    }, [maxBalance, totalStake])

    useEffect(() => {
        import(`../../../assets/img/token/${farm.icon}.png`).then((path) => {
            setImagePath(path.default);
        });
    }, [farm])
    const isPairToken = farm.icon.includes("-");
    const poolActive = true;

    if (stakedOnly && userStake.isZero()) {
        return null
    }

    if (display === 'active') {
        if (
            (!(parseFloat(apy) > 0))
            &&
            (!isNaN(parseFloat(apy)))
        )
            return null
    }
    if (display === 'inactive') {
        if ((parseFloat(apy) > 0))
            return null
    }
    return (
        <StyledCardWrapper>
            {farm.tokenSymbol === "SUSHI" && <StyledCardAccent />}
            <Card>
                <CardContent>
                    <StyledContent>
                        <StyledMagnification>{farm.magnification}X</StyledMagnification>
                        <StyledCardIcon
                            style={isPairToken ? { height: "40px", marginTop: "30px" } : {}}
                        >
                            <StyledIconImage
                                style={isPairToken ? { height: "40px" } : {}}
                                src={imagePath}
                                alt="token-icon"
                            />
                        </StyledCardIcon>
                        <StyledDetails>
                            <StyledDetail>

                                <StyledDetailSpan>
                                    <img src={DepositImg} alt={"icon"} />
                                    {t("deposit")}
                                </StyledDetailSpan>

                                <StyledDetailSpan>
                                    {farm.stakingToken.toUpperCase()}
                                </StyledDetailSpan>
                            </StyledDetail>
                            <StyledDetail>

                                <StyledDetailSpan>
                                    <img src={HarvestImg} alt={"icon"} />
                                    {t("harvest")}
                                </StyledDetailSpan>
                                <StyledDetailSpan>
                                    {(farm.stakingToken.toUpperCase() === 'MDEX EDC-USDT LP' ? "" : "") + farm.earnToken.toUpperCase()}
                                </StyledDetailSpan>
                            </StyledDetail>
                            <StyledDetail>
                                <StyledDetailSpan>
                                    <img src={APYImg} alt={"icon"} />
                                    {`APY`}
                                </StyledDetailSpan>
                                <StyledDetailSpan>
                                    {apy}
                                </StyledDetailSpan>
                            </StyledDetail>
                            {
                                !!apr ?
                                    <StyledDetail>

                                        <StyledDetailSpan>
                                            <img src={APYImg} alt={"icon"} />
                                            {`APR`}
                                        </StyledDetailSpan>
                                        <StyledDetailSpan>
                                            {apr}
                                        </StyledDetailSpan>
                                    </StyledDetail>
                                    : null

                            }
                        </StyledDetails>
                        <div style={{ height: '5x' }}></div>
                        {
                            !!progressCtx ? (
                                <div style={{
                                    width: "100%",
                                    position: 'relative',
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    height: "75px",
                                }}>

                                    {progressCtx.loading ? <Spin style={{ color: 'yellow' }} /> : null}
                                    <div
                                        style={{
                                            position: "relative",
                                            left: `calc( ${parseFloat(progressCtx.prog) < 5 ? "5.00" :
                                                parseFloat(progressCtx.prog) > 96.5 ? "96.50" : progressCtx.prog}% - 2em )`,
                                            alignSelf: "flex-start",
                                        }}
                                    >
                                        <div style={{
                                            position: "absolute",
                                            bottom: -8,
                                            left: "50%",
                                        }}>
                                            <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M2 4L0 0L4 0L2 4Z" fill="#FB917E" />
                                            </svg>
                                        </div>
                                        {progressCtx.prog}%</div>

                                    <Progress
                                        strokeColor={{
                                            '0%': '#FECA25',
                                            '100%': '#FB917E',
                                        }}
                                        percent={parseFloat(progressCtx.prog)}
                                        showInfo={false}
                                        trailColor={"#979797"} />

                                    {
                                        progressCtx.loading ? null :
                                            <div style={{ marginBottom: '8px' }}>
                                                剩余可投:
                                                {
                                                    progressCtx
                                                        .max
                                                        .minus(progressCtx.cur)
                                                        .dividedBy(new BigNumber(10).pow(farm.decimal))
                                                        .toFixed(2)
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                                        
                                                }
                                            </div>
                                    }
                                </div>
                            ) : null

                        }


                        <Button
                            disabled={!poolActive}
                            text={poolActive ? `${t("deposit")} ➞` : undefined}
                            to={`/${farm.page}/${farm.id}`}
                        >
                            {!poolActive && (
                                <Countdown
                                    date={new Date(0 * 1000)}
                                    renderer={renderer}
                                />
                            )}
                        </Button>
                        <div style={{ height: '14px' }}></div>
                        <StyledDetails style={{ marginTop: 0 }}>
                            <StyledDetail style={{ alignItems: "center" }}>
                                <StyledDetailSpan style={{ flexBasis: "25%" }}>{t("totalStaked")}</StyledDetailSpan>
                                <StyledDetailSpan style={{ textAlign: "right" }}>
                                    <span style={{ fontWeight: 'bold', textAlign: "right" }}>
                                        {addDisplayBalance(totalStake, farm.decimal, farm.page, farm.name)}
                                        {farm.stakingToken.toUpperCase()}</span></StyledDetailSpan>
                            </StyledDetail>
                        </StyledDetails>
                    </StyledContent>
                </CardContent>
            </Card>
        </StyledCardWrapper >
    );
};

const RainbowLight = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const StyledCardAccent = styled.div`
  background: linear-gradient(
    45deg,
    rgba(255, 0, 0, 1) 0%,
    rgba(255, 154, 0, 1) 10%,
    rgba(208, 222, 33, 1) 20%,
    rgba(79, 220, 74, 1) 30%,
    rgba(63, 218, 216, 1) 40%,
    rgba(47, 201, 226, 1) 50%,
    rgba(28, 127, 238, 1) 60%,
    rgba(95, 21, 242, 1) 70%,
    rgba(186, 12, 248, 1) 80%,
    rgba(251, 7, 217, 1) 90%,
    rgba(255, 0, 0, 1) 100%
  );
  background-size: 300% 300%;
  animation: ${RainbowLight} 2s linear infinite;
  border-radius: 12px;
  filter: blur(6px);
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  z-index: -1;
`;




const StyledCardWrapper = styled.div`
  display: flex;
  width: calc((900px - ${(props) => props.theme.spacing[4]}px * 2) / 3);
  position: relative;
  margin-right:16px;
  @media (max-width: 768px){
    margin:0;
  }
`;

const StyledCardIcon = styled.div`
  background: #FBFBFB;
  border-radius: 80px;
  box-shadow: 0px 0px 3px 0px rgba(166, 194, 217, 0.5);
  height: 70px;
  width: 70px;
  align-items: center;
  display: flex;
  justify-content: center;

  margin: 20px auto ${props => props.theme.spacing[3]}px;
`

const StyledIconImage = styled.img`
  height: 60px;
  width: 60px;
  display: block;
`;

const StyledContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledDetails = styled.div`
  width: 100%;

  margin-top: 8px;
`

const StyledDetail = styled.div`
  color: #5E4806;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  margin-bottom: 8px;
`

const StyledDetailSpan = styled.span`
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  &:last-of-type {
    color: #333333;
  }
  > img {
    margin-right: 10px;
    position: relative;
    top: 3px;
  }
`;

const StyledMagnification = styled.div`
  position: absolute;

  right: -16px;
  top: -16px;
  background-color: #FFEBAC;
  border-radius: 0 12px 0 12px;
  width: 52px;
  height: 24px;
  line-height: 24px;
  color: #3F3106;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
`;


export default FarmCard