import React from "react";
import styled from "styled-components";

import Container from "../../../components/Container";

interface PageHeaderProps {
  icon?: React.ReactNode;
  subtitle?: string;
  subtitle2?: string;
  title?: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  icon,
  subtitle,
  subtitle2,
  title,
}) => {
  return (
    <Container size="sm">
      <StyledPageHeader >
        {icon && <StyledIcon>{icon}</StyledIcon>}
        {title && <StyledTitle>{title}</StyledTitle>}
        {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
        {subtitle2 && <StyledSubtitle>{subtitle2}</StyledSubtitle>}
      </StyledPageHeader>
    </Container>
  );
};

const StyledPageHeader = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-bottom: ${(props) => props.theme.spacing[6]}px;
  padding-top: ${(props) => props.theme.spacing[6]}px;
  margin: 0 auto;
  margin-top: 70px;
  @media (max-width: 768px) {
    margin: 118px auto 0;
  }
`;

const StyledIcon = styled.div`
  font-size: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  width: 120px;
`;

const StyledTitle = styled.h1`
  color: ${(props) => props.theme.color.yellow};
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  padding: 0;
`;

const StyledSubtitle = styled.h3`
  color: ${(props) => props.theme.color.yellow};
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
`;

export default PageHeader;
