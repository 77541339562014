import React, { useRef, useEffect, useState } from 'react'
import './index.css'
import styled from 'styled-components'


interface TextScrollProps {
  /**
   * 内容
   */
  content: string
  /**
   * 持续时间/s
   */
  duration: number
}

type TextProps= {
     animationName:string ,
     timing:number,
      contentWidth:number,
      left:number 
}

  const Text = styled.p<TextProps>`
  //公告移动电脑端
    margin:0;
    color:#909090;
    @media screen and (min-width:1024px) {
      position: relative;
      left: ${e=>e.left}px;
      animation: ${e=>e.animationName} ${e=>e.timing}s linear infinite both;
      animation-play-state: running;
      animation-fill-mode: forwards;
    }
    @keyframes ${e=>e.animationName} {
         0% {
          transform: translateX(0px);
          }
        100% {
          transform: translateX(-${e=>e.contentWidth + e.left}px);
          }
    }

      //公告移动平板端
       @media screen and (min-width:500px) and (max-width:1000px) {
        position: relative;
        left: 600px;
        animation: ${e=>e.animationName} ${e=>e.timing}s linear infinite both;
        animation-play-state: running;
        animation-fill-mode: forwards;
        @keyframes ${e=>e.animationName} {
          0% {
              transform: translateX(0px);
           }
          100% {
               transform: translateX(-${e=>e.contentWidth + e.left}px);
           }
        }
   }


   //公告移动手机端
    @media screen and (max-width:500px) {
         position: relative;
        left: 200px;
        margin: 8px 0;
         animation: ${e=>e.animationName} ${e=>e.timing}s linear infinite both; animation-play-state: running;
        animation-fill-mode: forwards;
    color:#909090;
      @keyframes ${e=>e.animationName} {
           0% {
               transform: translateX(0px);
            }
           100% {
                transform: translateX(-${e=>e.contentWidth + e.left}px);
            }
      }
 
    }
    
  `

function TextScroll(props: TextScrollProps) {
  const { content, duration } = props

  const defaultState = {
    contentWidth: 0,
    left: 0,
    duration,
  }

  const [state, setState] = useState(defaultState)

  let ref = useRef<HTMLParagraphElement>(null)

  useEffect(() => {
    const { offsetWidth, parentElement } = ref.current as HTMLParagraphElement

    setState(e=>({
      ...e,
      contentWidth: offsetWidth,
      left: parentElement!.offsetWidth,
    }))
  }, [])

  const { contentWidth, left, duration: timing } = state
  const animationName = `marquee_${contentWidth}`


  return (
    <div className="marquee_box">
      <Text 
      animationName={animationName}
      timing={timing}
      left={left}
      contentWidth={contentWidth}
      ref={ref} 
      >{content}</Text>
    </div>
  )
}
TextScroll.defaultProps = {
  content: '',
  duration: 30,
}

export default React.memo(TextScroll)
