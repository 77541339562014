import React, { useCallback } from "react";
import styled from "styled-components";
import { useWallet } from "use-wallet";
import useModal from "../../../hooks/useModal";
import WalletProviderModal from "../../WalletProviderModal";
import AccountModal from "./AccountModal";

import { useTranslation } from "react-i18next";
import ClaimModal from "../../ClaimModal";
import Lang from "./Lang";

interface AccountButtonProps { }

const ButtonArea = styled.div`
  display: flex;
`;

const AccountButton: React.FC<AccountButtonProps> = () => {
  const [onPresentAccountModal] = useModal(<AccountModal />);
  const [onPresentWalletProviderModal] = useModal(
    <WalletProviderModal />,
    "provider"
  );

  const [onPresentClaimModal] = useModal(<ClaimModal />, "claim-modal");

  const { account } = useWallet();

  const handleUnlockClick = useCallback(() => {
    onPresentWalletProviderModal();
  }, [onPresentWalletProviderModal]);

  const { t } = useTranslation();

  const ellipsed_addr = (account: string) =>
    `${account.slice(0, 6)}...${account.slice(-4)}`;

  return (
    <>
      <ButtonArea>
        <StyledAccountButton style={{ marginLeft: "8px" }}>
          <StyledHeaderButton
            onClick={account ? onPresentClaimModal : () => undefined}
          >
            {t("Claim")}
          </StyledHeaderButton>
        </StyledAccountButton>

        <StyledAccountButton>
          {!account ? (
            <StyledHeaderButton onClick={handleUnlockClick}>
              {t("unlockWallet")}
            </StyledHeaderButton>
          ) : (
              <StyledHeaderButton
                onClick={onPresentAccountModal}
              >{`${ellipsed_addr(account)}`}</StyledHeaderButton>
            )}
        </StyledAccountButton>

        <StyledAccountButton>
          <Lang />
        </StyledAccountButton>
      </ButtonArea>
    </>
  );
};

const StyledAccountButton = styled.div`
  margin-left: 8px;
  display:flex;
  align-items:center;
`;
const StyledHeaderButton = styled.button`
white-space:nowrap;
  background: rgba(0, 0, 0, 0);
  border: 0;
  height: 30px;
  border: 1px solid #edb605;
  color: #edb605;
  border-radius: 5px;
  cursor: pointer;
  outline-style: none;
  padding: 0 20px;
  @media (max-width:768px){
    padding: 0 5px;
  }
  
`;
export default AccountButton;
