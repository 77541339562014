import React, { useState, useEffect } from "react";
import CountUp from "react-countup";

import styled from "styled-components";

interface ValueProps {
  value: string | number;
  decimals?: number;
  disableCountUp?: boolean;
  container?: typeof StyledValue;
}

const Value: React.FC<ValueProps> = ({
  value,
  decimals,
  disableCountUp,
  container,
}) => {
  const [start, updateStart] = useState(0);
  const [end, updateEnd] = useState(0);
  const Container = container ?? StyledValue;

  useEffect(() => {
    if (typeof value === "number") {
      if (end!== value){
        updateEnd(value);
        setImmediate(()=> updateStart(end))
      }
    }
  }, [value,end]);
  const duration = disableCountUp ? 0 : 2;
  const dec =
    decimals !== undefined ? decimals : end < 0 ? 4 : end > 1e5 ? 0 : 3;

  return (
    <Container>
      {typeof value == "string" ? (
        value
      ) : disableCountUp ? (
        value.toFixed(dec)
      ) : (
        <CountUp
          start={start}
          end={end}
          duration={duration}
          decimals={
            decimals !== undefined ? decimals : end < 0 ? 4 : end > 1e5 ? 0 : 3
          }
        />
      )}
    </Container>
  );
};

const StyledValue = styled.div`
  font-family: "Roboto Mono", monospace;
  color: #000000;
  font-size: 36px;
  font-weight: 700;
`;

export default Value;
