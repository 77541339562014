import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useWallet } from "use-wallet";
import { provider } from "web3-core";
import PageHeader from "./components/PageHeader";
import Spacer from "../../components/Spacer";
import useFarm from "../../hooks/useFarm";
import { getContract } from "../../utils/erc20";
import Harvest from "./components/Harvest";
import Stake from "./components/Stake";
import { useTranslation } from "react-i18next";
import { useCardInfoUpdater, usePoolInfoUpdater } from "../../hooks/useCardInfo";
const StyledIconImage = styled.img`
  height: 60px;
  width: 60px;
  display: block;
`;


const Farm: React.FC = () => {
  const { farmId } = useParams<{ farmId?: string }>();
  const farm = useFarm(farmId);
  useCardInfoUpdater(farm)
  const {
    pid,
    stakingToken,
    stakingTokenAddress,
    isWBNB,
    decimal,
  }: any = useFarm(farmId) || {
    pid: 0,
    stakingToken: "",
    stakingTokenAddress: "",
    earnToken: "",
    name: "",
    isWBNB: false,
    decimal: 18,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const sushi = useSushi()
  


  const { ethereum } = useWallet();
  const { t } = useTranslation();

  const lpContract = useMemo(() => {
    return getContract(ethereum as provider, stakingTokenAddress);
  }, [ethereum, stakingTokenAddress]);

  // const { onRedeem } = useRedeem(getMasterChefContract(sushi))

  const lpTokenName = useMemo(() => {
    return stakingToken.toUpperCase();
  }, [stakingToken]);

  const [imagePath, setImagePath] = useState("");
  usePoolInfoUpdater(farm)


  const loadTokenImage = (name: string): void => {
    import(`../../assets/img/token/${name}.png`).then((path) => {
      setImagePath(path.default);
    });
  };

  useEffect(() => {
    loadTokenImage(farm.icon);
  }, [farm.icon]);
  return (
    <>
      <PageHeader
        icon={
          <StyledIconImage
            src={imagePath}
            alt="token-icon"
          />
        }
        title={`${t("deposit")} ${lpTokenName} ${t("tokensAndEarn")} ${farm.earnToken}`}
      />
      <StyledFarm>
        <StyledCardsWrapper>
          <StyledCardWrapper>
            <Harvest pid={pid} farm={farm} />
          </StyledCardWrapper>
          <Spacer />
          <StyledCardWrapper>
            <Stake
              pid={pid}
              lpContract={lpContract}
              tokenName={stakingToken.toUpperCase()}
              isWBNB={isWBNB}
              decimal={decimal}
            />
          </StyledCardWrapper>
        </StyledCardsWrapper>
        <Spacer size="lg" />
      </StyledFarm>
    </>
  );
};

const StyledFarm = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledCardsWrapper = styled.div`
  display: flex;
  width: 700px;
  margin-top: 70px;
  @media (max-width: 768px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: center;
  }
`;

const StyledCardWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 80%;
  }
`;


export default Farm;
