import { EDCServiceSet, PoolsConfig, PoolsRequiredConfig } from "./types"

const service: EDCServiceSet = {
    earned1: { method: "earned2", },
    staked1: { method: "getWantBalance", },
    staked2: { method: "balanceOf", },
    deposit: { method: "stake", },
    withdraw: { method: "withdraw", },
    exit: { method: "exit", },
    harvest: { method: "getReward2", },
    apy: {
        method: "",
        rest: { endpoint: "/api/apy/" }
    }
}
const cfg: PoolsRequiredConfig[] = [

    {
        poolAddresses: {
            128: "0xCB32897433a841AA0d281D605874F7Cb91Dc8FC2",
        },
        stakingTokenAddresses: {
            128: "0xFBe7b74623e4be82279027a286fa3A5b5280F77c",
        },
        name: "HBTC/USDT",
        symbol: "HBTC/USDT",
        tokenSymbol: "HBTC/USDT",
        earnToken: "HBTC/USDT",
        earnDecimal: 9,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0xA28aE2Cb7A5689fa1120b3ba6ad0A733CDBBd6aa",
        },
        stakingTokenAddresses: {
            128: "0x78C90d3f8A64474982417cDB490E840c01E516D4",
        },
        name: "ETH/USDT",
        symbol: "ETH/USDT",
        tokenSymbol: "ETH/USDT",
        earnToken: "ETH/USDT",
        earnDecimal: 9,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0xFdfB4a888d10CE483DA432Bd41B9dcf727234A4b",
        },
        stakingTokenAddresses: {
            128: "0xdff86B408284dff30A7CAD7688fEdB465734501C",
        },
        name: "HUSD/USDT",
        symbol: "HUSD/USDT",
        tokenSymbol: "HUSD/USDT",
        earnToken: "HUSD/USDT",
        earnDecimal: 9,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0xA95e9C3a9b147A94b37Ca7d1aee580782A66Fd23",
        },
        stakingTokenAddresses: {
            128: "0x060B4bfcE16D15A943ec83C56C87940613e162eB",
        },
        name: "HLTC/USDT",
        symbol: "HLTC/USDT",
        tokenSymbol: "HLTC/USDT",
        earnToken: "HLTC/USDT",
        earnDecimal: 9,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x966E32FaC3474BA58bA19784AF3A432DAE111Dd3",
        },
        stakingTokenAddresses: {
            128: "0x1f0eC8e0096e145f2bf2Cb4950Ed7b52d1cbd35f",
        },
        name: "HBCH/USDT",
        symbol: "HBCH/USDT",
        tokenSymbol: "HBCH/USDT",
        earnToken: "HBCH/USDT",
        earnDecimal: 9,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x85a2d3dF27170f076609E149c33e6141128a5f43",
        },
        stakingTokenAddresses: {
            128: "0x5484ab0DF3E51187f83F7f6b1a13f7a7Ee98C368",
        },
        name: "HDOT/USDT",
        symbol: "HDOT/USDT",
        tokenSymbol: "HDOT/USDT",
        earnToken: "HDOT/USDT",
        earnDecimal: 9,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0xa27048534d356cC542D59DF33f113dACec0AAd77",
        },
        stakingTokenAddresses: {
            128: "0x600072aF0470d9Ed1D83885D03d17368943fF22A",
        },
        name: "HFIL/USDT",
        symbol: "HFIL/USDT",
        tokenSymbol: "HFIL/USDT",
        earnToken: "HFIL/USDT",
        earnDecimal: 9,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0xe80Cd3Ee675854C9Fd50FcD0e5d898d8D50717E4",
        },
        stakingTokenAddresses: {
            128: "0x3375afF2CAcF683b8FC34807B9443EB32e7Afff6",
        },
        name: "WHT/HUSD",
        symbol: "WHT/HUSD",
        tokenSymbol: "WHT/HUSD",
        earnToken: "WHT/HUSD",
        earnDecimal: 9,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x0bF03819e71c790236Fcf74ECAf7EB986434a716",
        },
        stakingTokenAddresses: {
            128: "0x615E6285c5944540fd8bd921c9c8c56739Fd1E13",
        },
        name: "MDX/USDT",
        symbol: "MDX/USDT",
        tokenSymbol: "MDX/USDT",
        earnToken: "MDX/USDT",
        earnDecimal: 9,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x5cE5bcce087EF3d90Bf071EC0D81106b75cCf3d8",
        },
        stakingTokenAddresses: {
            128: "0x499B6E03749B4bAF95F9E70EeD5355b138EA6C31",
        },
        name: "WHT/USDT",
        symbol: "WHT/USDT",
        tokenSymbol: "WHT/USDT",
        earnToken: "WHT/USDT",
        earnDecimal: 9,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0xcf022bBEE1962557069B7d344463E5d1E92A77A9",
        },
        stakingTokenAddresses: {
            128: "0xdE5b574925EE475c41b99a7591EC43E92dCD2fc1",
        },
        name: "HPT/USDT",
        symbol: "HPT/USDT",
        tokenSymbol: "HPT/USDT",
        earnToken: "HPT/USDT",
        earnDecimal: 9,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0xC88491752503A3d7EBBdFcc36319636b96Bfe43e",
        },
        stakingTokenAddresses: {
            128: "0x6Dd2993B50b365c707718b0807fC4e344c072eC2",
        },
        name: "MDX/WHT",
        symbol: "MDX/WHT",
        tokenSymbol: "MDX/WHT",
        earnToken: "MDX/WHT",
        earnDecimal: 9,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0xe7551ed1D58aCEa09Da35F5FBeD03734A7c0cF63",
        },
        stakingTokenAddresses: {
            128: "0x7964E55BBdAECdE48c2C8ef86E433eD47FEcB519",
        },
        name: "FILDA/HUSD",
        symbol: "FILDA/HUSD",
        tokenSymbol: "FILDA/HUSD",
        earnToken: "FILDA/HUSD",
        earnDecimal: 9,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x9Af199FaE7a80c2e3136443Bf92064FDbE4A0808",
        },
        stakingTokenAddresses: {
            128: "0x023f375a51Af8645D7446ba5942BAeDc53B0582D",
        },
        name: "LHB/USDT",
        symbol: "LHB/USDT",
        tokenSymbol: "LHB/USDT",
        earnToken: "LHB/USDT",
        earnDecimal: 9,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x2d286C31410bC070d45d2641cd2e35F0cF4a531b",
        },
        stakingTokenAddresses: {
            128: "0xBFff969A85e355eE0851b019Dba1e87C7780F40d",
        },
        name: "HBTC/WHT",
        symbol: "HBTC/WHT",
        tokenSymbol: "HBTC/WHT",
        earnToken: "HBTC/WHT",
        earnDecimal: 9,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x5d55Fe59d3F2813561d3504cC0ec65239d32aE29",
        },
        stakingTokenAddresses: {
            128: "0x53E458aD1CFEB9582736db6BdE9aF89948e3bc3d",
        },
        name: "ETH/WHT",
        symbol: "ETH/WHT",
        tokenSymbol: "ETH/WHT",
        earnToken: "ETH/WHT",
        earnDecimal: 9,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0xa87DA6bf9ac486Db472820D9c95399f7ADDDe908",
        },
        stakingTokenAddresses: {
            128: "0x793c2a814e23EE38aB46412Be65E94Fe47D4B397",
        },
        name: "HBTC/ETH",
        symbol: "HBTC/ETH",
        tokenSymbol: "HBTC/ETH",
        earnToken: "HBTC/ETH",
        earnDecimal: 9,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x4e512B87ed06f7C671a5F8796DA2D154e483016b",
        },
        stakingTokenAddresses: {
            128: "0xE09A504071C0c1483E86727fb95f550a0559d9D2",
        },
        name: "VNLA",
        symbol: "VNLA",
        tokenSymbol: "VNLA",
        earnToken: "VNLA",
        earnDecimal: 9,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0xd6A388f60028ED1Baa6149Cf0FF75Bc663126010",
        },
        stakingTokenAddresses: {
            128: "0x2E1f9656d09e70A176F970Ca60aeB316F91584B9",
        },
        name: "VNLA/HUSD",
        symbol: "VNLA/HUSD",
        tokenSymbol: "VNLA/HUSD",
        earnToken: "VNLA/HUSD",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },

    {
        poolAddresses: {
            128: "0x1C4A36Bc041f75c6829fF625D6b9F9A87A9927Fa",
        },
        stakingTokenAddresses: {
            128: "0x8b65b86dc3CaDcA97b50D4757BB8A686E6ea0cE1",
        },
        name: "DEPTH HUSD/USDT",
        symbol: "DEPTH HUSD/USDT",
        tokenSymbol: "DEPTH HUSD/USDT",
        earnToken: "DEPTH HUSD/USDT ",
        earnDecimal: 9,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x3350DD49D239DcFA1188E07287d3Baa97e636249",
        },
        stakingTokenAddresses: {
            128: "0xC95239fE2bAAbDbd15Eec26805156E219b12FfcE",
        },
        name: "DEP/HUSD",
        symbol: "DEP/HUSD",
        tokenSymbol: "DEP/HUSD",
        earnToken: "DEP/HUSD",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x897Ba1b865Dc1117D0D5F0D85265874c00A6EC13",
        },
        stakingTokenAddresses: {
            128: "0x0DEE1dBEb5F6a2308e498a88daA939987F2231aB",
        },
        name: "DEP/HT",
        symbol: "DEP/HT",
        tokenSymbol: "DEP/HT",
        earnToken: "DEP/HT",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },

]

const reinvest2Pool: PoolsConfig[] = cfg
    .map<PoolsConfig>((e) => ({
        ...e,
        page: "reinvest2",
        symbol: e.name,
        tokenSymbol: e.name,
        decimal: e.decimal ?? 18,
        isWBNB: e.isWBNB ?? false,
        magnification: e.magnification ?? 4,
        earnToken: "EDC",
        earnDecimal: 9,
        apyName: "husdht",
        service

    }))
export default reinvest2Pool