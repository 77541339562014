import BigNumber from 'bignumber.js'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useWallet } from 'use-wallet'
import { Contract } from 'web3-eth-contract'
import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardContent from '../../../components/CardContent'
import IconButton from '../../../components/IconButton'
import { AddIcon } from '../../../components/icons'
import Label from '../../../components/Label'
import Value from '../../../components/Value'
import useAllowance from '../../../hooks/useAllowance'
import useApprove from '../../../hooks/useApprove'
import useModal from '../../../hooks/useModal'
import useTokenBalance from '../../../hooks/useTokenBalance'
import { getBalanceNumber } from '../../../utils/formatBalance'
import DepositModal from './DepositModal'
import WithdrawModal from './WithdrawModal'
import CardIcon from './CardIcon'
import HarvestIcon from '../../../assets/img/harvest-icon.png'
import { getCookie } from '../../../utils/cookie'
import { useTranslation } from 'react-i18next'
import useFarm from '../../../hooks/useFarm'
import { isLPVault, isReinvest2 } from '../../../contexts/Farms/types'
import { useTypedSelector } from '../../../libs/state/store'
import { GetBalanceof } from '../../../hooks/useCardInfo'
import { useCardAction } from '../../../hooks/useCardAction'

interface ValueFieldProps {
  symbol: string;
  value: number;
  decimals: number;
}

const ValueField: React.FC<ValueFieldProps> = ({ symbol, decimals, value }) => {
  return (
    <StyledValueFieldRoot>
      <StyledValueLine>
        <Value decimals={6} value={value} />
        <Label text={`${symbol + ((symbol.indexOf("/") === -1 && symbol.indexOf("HBO") === -1) ? " 待收获" : " 已自动复投")}`} />
      </StyledValueLine>
    </StyledValueFieldRoot>
  );
};

interface StakeProps {
  lpContract: Contract
  pid: number
  tokenName: string,
  isWBNB: boolean,
  decimal?: number
}

const Stake: React.FC<StakeProps> = ({ lpContract, pid, tokenName, isWBNB, decimal }) => {

  const farm = useFarm(pid);
  const [requestedApproval, setRequestedApproval] = useState(false)
  const { t } = useTranslation()
  const { balance } = useWallet();
  const { onApprove } = useApprove(lpContract, pid)
  const {
    onDeposit,
    onExit,
    onHarvest,
    onWithdraw,
  } = useCardAction(farm)



  const allowance = useAllowance(lpContract, pid)
  const tokenBalance = useTokenBalance(lpContract.options.address)
  const staked1 = useTypedSelector(e => e.PoolReducer?.[farm.pid]?.staked1 ?? new BigNumber(0))
  const staked2 = useTypedSelector(e => e.PoolReducer?.[farm.pid]?.staked2 ?? new BigNumber(0))
  const nav = useTypedSelector(e => e.PoolReducer?.[farm.pid]?.nav?? new BigNumber(0))
  const stakedValue = getBalanceNumber(staked1, decimal);
  const staked2Value = getBalanceNumber(staked2, farm.earnDecimal);
  const isLP = isLPVault(farm);
  const onDecoratedDeposit = (amount: string, decimal?: number) => {
    let value = new BigNumber(amount).times(new BigNumber(10).pow(decimal || 18))
    if (farm.isWBNB)
      value = amount.length < 8 ? value : new BigNumber(value).minus(1e15)
    if (value.gt(tokenBalance))
      value = tokenBalance

    console.log("deposit value",value.toFixed(),tokenBalance.toFixed())
    onDeposit(value, farm.isWBNB)
  }
  const OnDecoratedWithdraw = async (amount: string, decimal?: number) => {
    let value = new BigNumber(amount).times(new BigNumber(10).pow(decimal || 18))
    const stakedBalance =  GetBalanceof(farm,staked1,staked2)
    if(isReinvest2(farm) && !nav.isZero()){
      value = value.dividedBy(nav).multipliedBy(new BigNumber("1e18"))
    }
    if(stakedBalance.lte(value) )
      value =stakedBalance

    console.log("withdraw value",value.toFixed(), stakedBalance.toFixed())
    onWithdraw(value)
  }

  const [onPresentDeposit] = useModal(
    <DepositModal
      max={isWBNB ? new BigNumber(balance) : tokenBalance}
      onConfirm={onDecoratedDeposit}
      tokenName={tokenName}
      decimal={decimal}
    />,
  )


  const [onPresentWithdraw] = useModal(
    <WithdrawModal
      max={staked1}
      onConfirm={OnDecoratedWithdraw}
      tokenName={tokenName}
      decimal={decimal}
    />,
  )

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      const txHash = await onApprove()
      if (!txHash) {
        setRequestedApproval(false)
      }
    } catch (e) {
      console.log(e)
    }
  }, [onApprove, setRequestedApproval])

  function GetStakeType() {
    const c = getCookie('invite_id')
    if (c) {
      console.log('with ref')
      return (
        <IconButton onClick={onPresentDeposit}>
          <AddIcon />
        </IconButton>
      )
    } else {
      return (
        <IconButton onClick={onPresentDeposit}>
          <AddIcon />
        </IconButton>
      )
    }
  }

  const ProcessedStaked1 = useMemo(() => {
    if (isLP) {
      return stakedValue + staked2Value
    }
    return stakedValue
  }, [isLP, staked2Value, stakedValue])

  return (
    <Card>
      <CardContent>
        <StyledCardContentInner>
          <StyledCardHeader>
            <CardIcon>
              <StyledImageIcon src={HarvestIcon} alt="icon" />
            </CardIcon>
            {isLP && (
              <>
                <ValueField
                  symbol={farm.earnToken}
                  value={staked2Value}
                  decimals={farm.earnDecimal}
                />
              </>
            )}
            <Value disableCountUp value={ProcessedStaked1} decimals={6} />
            <Label text={`${tokenName} ${isReinvest2(farm) ? t("tokenReinvested") : t("tokensStaked")}`} />
          </StyledCardHeader>
          <StyledCardActions>
            {!isWBNB && !allowance.toNumber() ? (
              <Button
                disabled={requestedApproval}
                onClick={handleApprove}
                text={`${t("approve")} ${tokenName}`}
              />
            ) : (
              <>
                <Button
                  disabled={staked1.eq(new BigNumber(0)) && !(isLP)}
                  text={isLP ? t("lpunstake") : t("unstake")}
                  onClick={isLP ? () => onWithdraw(new BigNumber(0)) : onPresentWithdraw}
                />
                <StyledActionSpacer />
                <Button
                  text={isLP ? t("Exit") : t("Exit")}
                  onClick={() => staked1.eq(new BigNumber(0)) ? onHarvest() : onExit()}
                />
                <StyledActionSpacer />
                <GetStakeType />
              </>
            )}
          </StyledCardActions>
        </StyledCardContentInner>
      </CardContent>
    </Card>
  )
}

const StyledCardHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`
const StyledCardActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing[6]}px;
  width: 100%;
`

const StyledActionSpacer = styled.div`
  height: ${(props) => props.theme.spacing[4]}px;
  width: ${(props) => props.theme.spacing[4]}px;
`

const StyledCardContentInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`

const StyledImageIcon = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`

const StyledValueFieldRoot = styled.div`
  min-width: 223px;
  margin-top: 3px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const StyledValueLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default Stake
