import React, { useMemo } from "react";
import styled from "styled-components";
import useFarms from "../../../hooks/useFarms";
import { FarmPageChoices } from "../../../contexts/Farms/types";
import FarmCard from "./FarmCard";

const StyledCards = styled.div`
  width: 900px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledRow = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing[4]}px;
  flex-flow: row wrap;
  @media (max-width: 768px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: center;
  }
`;


interface FarmCardsId {
  page: FarmPageChoices;
  display?: string
}
const FarmCards: React.FC<FarmCardsId> = ({ display, page }) => {
  const [farms] = useFarms();


  const row1 = useMemo(() => {
    return farms
      .filter((e) => e.page === page);

  }
    , [page])

  const row = row1


  return (
    <StyledCards>
      {!!row.length ? (
        <StyledRow key={page}>
          {row.filter(e => e !== null).map((farm, j) => (
            <React.Fragment key={j}>
              <FarmCard farm={farm} display={display} />
            </React.Fragment>
          ))}
        </StyledRow>
      ) : (
        null
      )}
    </StyledCards>
  );
};
export default FarmCards;
