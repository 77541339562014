import React from "react";
import styled from "styled-components"
import { useTypedSelector } from "../../libs/state/store"


const Root = styled.div`
position: fixed;
right:0;
bottom:0;
opacity:0.5;
color:white;



`;
const BlockNumber: React.FC = () => {
    const number = useTypedSelector(e => e.GlobalReducers.latestBlockNumber)
    return (
        <Root>
            {number}
        </Root>



    )

}
export default BlockNumber