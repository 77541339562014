import React from 'react'
import styled from 'styled-components'
import Creator1 from "../../assets/img/group-left.png"
import Creator2 from "../../assets/img/group-right.png"
import { useTranslation } from "react-i18next"

const Creator: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledCreator className="center-center">
      <StyledCreatorContent style={{whiteSpace:'nowrap'}} className="center-center">
        <img style={{height: '30px', width: '19px'}} src={Creator1} />
        <StyledTitle>{t("CreatorTitle")}</StyledTitle>
        <img style={{height: '30px', width: '19px'}} src={Creator2} />
      </StyledCreatorContent>
      <StyledText><span style={{whiteSpace:'nowrap'}}>{t("CreatorText1")}</span><span style={{whiteSpace:'nowrap'}}>{t("CreatorText2")}</span></StyledText>
    </StyledCreator>
  )
  }

const StyledCreator = styled.div`
    positon: relative;
    background: #2F3236;
    border-radius: 80px;
    width: 548px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    displsy: flex;
    flex-direction: row;
    margin: 20px 0;
    @media (min-width: 768px) {
        margin-top: 118px;
        margin-bottom: 15px;
    }
    @media (max-width: 768px) {
        width: 200px;
        flex-wrap: wrap;
        margin-top: calc( 96px + 8px );
        margin-bottom: 29px;
        margin-left: 8px;
    }
    @media (max-width:356px){
        margin-top: calc( 136px + 8px );

    }
`

const StyledText = styled.div`
  font-family: Noto Sans SC;
  text-align: center;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.02em;
  flex-wrap: wrap;
  max-width: 355px;
  width: 258px;
  word-wrap:break-word;
  word-break:break-all;

  color: #AFAEAA;
  margin: 10px 15px 10px 15px;
  @media (max-width: 768px) {
      position: absolute;
      top: 70px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      text-align: center;
      width: 250px;
      margin-top: calc( 96px + 8px );
      margin-left: 0;
      margin-right: 0;
  }
`

const StyledTitle = styled.div`
  font-family: Noto Sans SC;
  text-align: center;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.02em;

  font-weight: bold;
  font-size: 16px;
  color: #FDF4D7;
`
const StyledCreatorContent = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`
export default Creator