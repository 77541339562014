import { useEffect } from 'react'
import Web3 from 'web3'
import { provider } from 'web3-core'
import { useWallet } from 'use-wallet'
import { useDispatch } from 'react-redux'
import { ActSetState } from '../libs/state/global'

const useBlockUpdater = () => {
  const { ethereum }: { ethereum: provider } = useWallet()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!ethereum) return
    const web3 = new Web3(ethereum)
    const h = web3.eth.subscribe('newBlockHeaders', (e, n) => {
      dispatch(ActSetState({ latestBlockNumber: n.number }))
    })
    return () => h.unsubscribe()
  }, [ethereum])

  useEffect(() => {

    if (!ethereum) return
    if (ethereum) {
      const web3 = new Web3(ethereum)

      const h = setInterval(() => {
        web3.eth.getBlockNumber((e, n) => !e && dispatch(ActSetState({ latestBlockNumber: n })))
      }, 5000)
      return () => clearInterval(h)
    }

  }, [ethereum])



}

export default useBlockUpdater
