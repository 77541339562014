import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useWallet } from "use-wallet";
import { provider } from "web3-core";
import { CLAIM_ADDRESS } from "../constants/tokenAddresses";
import { ActSetState } from "../libs/state/global";
import { getContract } from "../utils/claim";

const useClaim = () => {

  const { account, ethereum } = useWallet();

  // console.log(account)

  const dispatch = useDispatch();

  const contract = useMemo(() => {
    return getContract(ethereum as provider, CLAIM_ADDRESS);
  }, [ethereum]);


  const onClaim = useCallback(async () => {
    dispatch(ActSetState({ claimStatus: "loading" }));
    const call = contract.methods.claim().send({ from: account });
    try {
      await call.on("transactionHash", (tx: any) => {
        console.log("claim done", tx);
      });
      dispatch(ActSetState({ claimStatus: "success" }));
    } catch (e) {
      dispatch(ActSetState({ claimStatus: "idle" }));
    } finally {
    }
  }, [account, contract.methods]);

  return { onClaim };
};

export const useClaimBalance = () => {
  const { account, ethereum } = useWallet();
  const [amount, setAmount] = useState(0);
  const contract = useMemo(() => {
    return getContract(ethereum as provider, CLAIM_ADDRESS);
  }, [ethereum]);
  const dispatch = useDispatch();
  // 获取 claim 状态
  contract.methods.isClaimd(account).call().then((e: any) => {
    dispatch(ActSetState({ claimStatus: e ? "success" : 'idle' }));
  })

  const refresh = useCallback(() => {
    if (!account) return;
    contract.methods
      .claimAmounts(account)
      .call({ from: account })
      .then((e: any) => setAmount(e / 1e9));
  }, [account, contract.methods]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return { amount, refresh };
};

// export const useClaimStatus = async () => {
//   const { account, ethereum } = useWallet();
//   const contract_addr = CLAIM_ADDRESS;
//   const [amount, setAmount] = useState(0);
//   const dispatch = useDispatch();
//   const contract = useMemo(() => {
//     return getContract(ethereum as provider, contract_addr);
//   }, [ethereum]);
//   contract.methods.isClaimd(account).call().then((e: any) => {
//     console.log(e)
//     dispatch(ActSetState({ claimStatus: e ? "loading" : 'idle' }));
//   })
//   return useClaimStatus
//   // console.log(blockClaimStatus)
//   // return { blockClaimStatus }
//   // return contract.methods.isClaimed((account)
// }



export default useClaim;
