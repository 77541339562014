import { Reducer } from "redux";
import { produce } from "immer";

const ActionEnum = {
  SetState: "[global]SetState",
  updatePoolInfo: "[global]updatePoolInfo"
};

type CachedPoolInfo = {
  apy: string
  staked: string
}


export const ActSetState = (state: Partial<IGlobalState>) => ({
  type: ActionEnum.SetState,
  state,
});

export const ActUpdatePoolCache = (data: { [key: string]: Partial<CachedPoolInfo> }) => ({
  type: ActionEnum.updatePoolInfo,
  data,
});

interface IGlobalState {
  showConnect: boolean;
  showClaim: boolean;
  claimStatus: "idle" | "loading" | "success";
  poolcache: {
    [key: string]: CachedPoolInfo
  }
  stakedOnly: boolean
  loadingPoolStake: boolean
  showMenu: boolean
  latestBlockNumber: number
  loadingHecoTX: boolean

}

const init: IGlobalState = {

  loadingHecoTX: false,
  showConnect: true,
  latestBlockNumber: 0,
  stakedOnly: false,
  loadingPoolStake: false,
  showClaim: false,
  claimStatus: "loading",
  poolcache: {},
  showMenu: true
};

export type TAction = ReturnType<typeof ActSetState> & ReturnType<typeof ActUpdatePoolCache>;

export const GlobalReducers: Reducer<IGlobalState, TAction> = (
  state = init,
  action
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionEnum.SetState:
        Object.assign(draft, action.state);
        return;
      case ActionEnum.updatePoolInfo:
        for (let key of Object.keys(action.data)) {
          draft.poolcache[key] = {
            ...draft.poolcache[key],
            ...action.data[key]
          }
        }
    }
  });
