import { EDCServiceSet, PoolsConfig, PoolsRequiredConfig } from "./types";

const service: EDCServiceSet = {
    earned1: { method: "earned", },
    staked1: { method: "balanceOf", },
    deposit: { method: "stake", },
    withdraw: { method: "withdraw", },
    exit: { method: "exit", },
    harvest: { method: "getReward", },
    apy: {
        method: "",
        rest: { endpoint: "/api/apy/" }
    }
}
const cfg: PoolsRequiredConfig[] = [
    {
        poolAddresses: {
            128: "0xF560d745545D97876E4E9690B4443179A1AeaE27",
        },
        stakingTokenAddresses: {
            128: "0x0000000000000000000000000000000000000000",
        },
        name: "HT ",
        symbol: "HT ",
        tokenSymbol: "HT ",
        isWBNB: true,
        icon: "ht",
    },
    {
        poolAddresses: {
            128: "0xc70B41c2309e11228b57d7bBEae97788e1A13E4a",
        },
        stakingTokenAddresses: {
            128: "0xa71EdC38d189767582C38A3145b5873052c3e47a",
        },
        name: "USDT ",
        symbol: "USDT ",
        tokenSymbol: "USDT ",
        decimal: 18,
        icon: "usdt",
    },
    {
        poolAddresses: {
            128: "0x0D6eaFAe2C3BC031344C1E9Cb83c8C4edceA5B62",
        },
        stakingTokenAddresses: {
            128: "0x0298c2b32eaE4da002a15f36fdf7615BEa3DA047",
        },
        name: "HUSD  ",
        symbol: "HUSD  ",
        tokenSymbol: "HUSD  ",
        decimal: 8,
        isWBNB: false,
        icon: "husd",
    },
    {
        poolAddresses: {
            128: "0xa1516f9d230e93d2d86b8e50dc83de3254e66d58",
        },
        stakingTokenAddresses: {
            128: "0x64ff637fb478863b7468bc97d30a5bf3a428a1fd",
        },
        name: "ETH ",
        symbol: "ETH ",
        tokenSymbol: "ETH ",
        icon: "eth",
    },
    {
        poolAddresses: {
            128: "0xBfada90cAe2Ca7e5CA6B5083Fa7AAb64393D6E91",
        },
        stakingTokenAddresses: {
            128: "0x66a79d23e58475d2738179ca52cd0b41d73f0bea",
        },
        name: "BTC ",
        symbol: "BTC ",
        tokenSymbol: "BTC ",
        icon: "hbtc",
    },
    {
        poolAddresses: {
            128: "0xD5790Ea047d0E91343c8a268dB17c077519B8DF0",
        },
        stakingTokenAddresses: {
            128: "0xeF3CEBD77E0C52cb6f60875d9306397B5Caca375",
        },
        name: "HBCH ",
        symbol: "HBCH ",
        tokenSymbol: "HBCH ",
        icon: "bch",
    },
    {
        poolAddresses: {
            128: "0xc35E6CC11936e30360fb3E3a2b3c98b5A78f1000",
        },
        stakingTokenAddresses: {
            128: "0xecb56cf772B5c9A6907FB7d32387Da2fCbfB63b4",
        },
        name: "HLTC ",
        symbol: "HLTC ",
        tokenSymbol: "HLTC ",
        icon: "ltc",
    },
    {
        poolAddresses: {
            128: "0x83E03Efb94514F2dbcffBd1F1e46b62291537582",
        },
        stakingTokenAddresses: {
            128: "0xc2CB6B5357CcCE1B99Cd22232942D9A225Ea4eb1",
        },
        name: "HBSV ",
        symbol: "HBSV ",
        tokenSymbol: "HBSV ",
        icon: "bsv",
    },
    {
        poolAddresses: {
            128: "0xA6810a56E9760F2C82307a6ca5C43F3a84EB2175",
        },
        stakingTokenAddresses: {
            128: "0xae3a768f9aB104c69A7CD6041fE16fFa235d1810",
        },
        name: "HFIL ",
        symbol: "HFIL ",
        tokenSymbol: "HFIL ",
        icon: "fil",
    },
    {
        page: "vault",
        poolAddresses: {
            128: "0x1Eb70199eB44983F79385cDd9ba942759b9c40DA",
        },
        stakingTokenAddresses: {
            128: "0xA2c49cEe16a5E5bDEFDe931107dc1fae9f7773E3",
        },
        name: "HDOT ",
        symbol: "HDOT ",
        tokenSymbol: "HDOT ",
        icon: "dot",
    },
    {
        poolAddresses: { 128: "0xfFB2baf0e91C4cC62784262072d343e4B64ddbC5" },
        stakingTokenAddresses: {
            128: "0xe499ef4616993730ced0f31fa2703b92b50bb536",
        },
        name: "HPT ",
        symbol: "HPT ",
        tokenSymbol: "HPT ",
        icon: "hpt",
    },
    {
        poolAddresses: {
            128: "0x8919bc287b0aafc508fdc60ce59dad49a4247e31",
        },
        stakingTokenAddresses: {
            128: "0xc036fb5687a8c0ab438faa5c744b55fd705a0c56",
        },
        name: "HQTUM ",
        symbol: "HQTUM ",
        tokenSymbol: "HQTUM ",
        icon: "hqtum",
        decimal: 8,
    },
    {
        poolAddresses: { 128: "0xa1ff47a772d4ed54692a8aba32c3c3a066620b8f" },
        stakingTokenAddresses: {
            128: "0x0298c2b32eaE4da002a15f36fdf7615BEa3DA047",
        },
        name: "HUSD ",
        symbol: "HUSD ",
        tokenSymbol: "HUSD ",
        icon: "husd",
        magnification: 0,
        decimal: 8,
    },


]

const vaultPool = cfg.map<PoolsConfig>((e) => ({
    ...e,
    page: "vault",
    symbol: e.name,
    tokenSymbol: e.name,
    decimal: e.decimal ?? 18,
    isWBNB: e.isWBNB ?? false,
    magnification: e.magnification ?? 4,
    earnToken: e?.earnToken ?? "HUSD",
    earnDecimal: e.earnDecimal ?? 8,
    apyName: "husdht",
    service,
}));
export default vaultPool