import React, { useCallback } from "react";
import styled from "styled-components";
import { useWallet } from "use-wallet";
import useTokenBalance from "../../../hooks/useTokenBalance";
// import useSushi from '../../../hooks/useSushi'
// import { getSushiAddress } from '../../../sushi/utils'
import { getBalanceNumber } from "../../../utils/formatBalance";
import Button from "../../Button";
import CardIcon from "../../CardIcon";
import Label from "../../Label";
import Modal, { ModalProps } from "../../Modal";
import ModalActions from "../../ModalActions";
import ModalContent from "../../ModalContent";
import ModalTitle from "../../ModalTitle";
import Spacer from "../../Spacer";
// import Value from '../../Value'
import decLogo from "../../../assets/img/logo.png";

import { useTranslation } from "react-i18next";
import { EDCAddress } from "../../../constants/addresses";

const AccountModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { account, reset } = useWallet();
  const { t } = useTranslation();

  const handleSignOutClick = useCallback(() => {
    onDismiss!();
    reset();
  }, [onDismiss, reset]);

  const sushiBalance = useTokenBalance(EDCAddress);

  return (
    <Modal>
      <ModalTitle text="My Account" />
      <ModalContent>
        <Spacer />

        <div style={{ display: "flex" }}>
          <StyledBalanceWrapper>
            <CardIcon>
              <img style={{ height: "46px" }} src={decLogo} alt="D" />
            </CardIcon>
            <StyledBalance>
              <StyledValue>
                {Math.round(getBalanceNumber(sushiBalance) * 100) / 100}
              </StyledValue>
              <Label text={t("edcBalance")} />
            </StyledBalance>
          </StyledBalanceWrapper>
        </div>

        <Spacer />
        <Button
          href={`https://scan.hecochain.com/address/${account}`}
          text={t("viewOnHecoScan")}
          variant="secondary"
        />
        <Spacer />
        <Button
          onClick={handleSignOutClick}
          text={t("signOut")}
          variant="secondary"
        />
      </ModalContent>
      <ModalActions>
        <Button onClick={onDismiss} text={t("cancel")} />
      </ModalActions>
    </Modal>
  );
};

const StyledBalance = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const StyledBalanceWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing[4]}px;
`;

const StyledValue = styled.div`
  font-family: "Roboto Mono", monospace;
  color: #000000;
  font-size: 36px;
  font-weight: 700;
  padding-top: 16px;
`;

export default AccountModal;
