
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ReducerState } from 'react';
import { GlobalReducers } from './global';
import { PoolReducer } from './pools';
import { createSelectorHook } from 'react-redux';

const reducer = combineReducers({
    GlobalReducers,
    PoolReducer,

});

export type RootState = ReducerState<typeof reducer>;

export const useTypedSelector = createSelectorHook<RootState>()

export const initializeStore = () => {
    return createStore(reducer, composeWithDevTools(applyMiddleware()));
};