import React  from "react";
import styled from "styled-components";

import Button from "../Button";
import Modal, { ModalProps } from "../Modal";
import ModalActions from "../ModalActions";
import ModalContent from "../ModalContent";

import { useTranslation } from "react-i18next";
import useClaim, { useClaimBalance } from "../../hooks/useClaim";
import ModalBg from "../Modal/ModalBg";
import { useTypedSelector } from "../../libs/state/store";
import Loading from "../../assets/img/loading.svg";

const ClaimModal: React.FC<ModalProps> = () => {
  const { amount } = useClaimBalance();

  const { onClaim } = useClaim();
  const { t } = useTranslation();
  const claimStatus = useTypedSelector((e) => e.GlobalReducers.claimStatus);

  const AskForClaim = (
    <>
      <ModalContent
        style={{
          backgroundColor: "#171923",
          padding: "32px 0 0 20px",
          borderRadius: "11px 11px 0px 0px",
          height: "156px",
        }}
      >
        <ClaimTxt>
          <p>Claim EDC</p>
          <p>{amount} EDC</p>
        </ClaimTxt>
        <ModalBg />
        <ContentDetail />
      </ModalContent>

      <ModalContent
        style={{
          padding: "20px",
        }}
      >
        <ContentDescription>{t("ClaimText")}</ContentDescription>
      </ModalContent>

      <ModalActions>
        {claimStatus === "idle" ? (
          <Button
            text={`${t("claimEDC")}`}
            variant="secondary"
            onClick={onClaim}
          />
        ) : claimStatus === "loading" ? (
          <Button
            variant="secondary"
            children={
              <img
                className="btnLoading"
                width="16px"
                height="16px"
                src={Loading}
                alt=""
              />
            }
          />
        ) : (
              <Claimed>Claimed</Claimed>
            )}
      </ModalActions>
    </>
  );

  return (
    <Modal
      style={{
        padding: "0",
        border: "0",
      }}
    >
      {claimStatus ? AskForClaim : null}
      {/* {claimStatus === "loading" ? ClaimLoading : null}
      {claimStatus === "success" ? ClaimSuccess : null} */}
    </Modal>
  );
};
const Claimed = styled.button`
  align-items: center;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  pointer-events: ${(props) => (!props.disabled ? undefined : "none")};
  width: 100%;
  outline-style: none;
  text-align: center;
  justify-content: center;
  padding: 7px 0;
  background: #fec60070;
`;
const ClaimTxt = styled.div`
  font-size: 16px;
  color: #fff5d3;
  > p:last-child {
    color: #edb605;
    font-size: 30px;
    position: relative;
    z-index: 1;
  }
`;

const ContentDetail = styled.div`
  min-height: 40px;
`;

const ContentDescription = styled.div`
  font-family: Source Han Sans CN;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  text-align: justify;
`;



export default ClaimModal;
