import styled from 'styled-components'


const StyledModalContent = styled.div`
  padding: ${(props) => props.theme.spacing[4]}px;
  position: relative;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    flex: 1;
    overflow: auto;
    > div {
      flex: 1;
    }
  }
`

export default StyledModalContent
