import Web3 from 'web3'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { provider } from 'web3-core'
// import { Contract } from 'web3-eth-contract'
import { AbiItem } from 'web3-utils'
import PoolABI from '../constants/abi/Pool.json'
import ReinvestABI from '../constants/abi/ReinvestPool.json'
import V3ABI from '../constants/abi/v3.json'
import { Farm } from '../contexts/Farms'
import { isReinvest2, isV3Pool } from '../contexts/Farms/types'


type GetContractOpts = {
  provider: provider,
  farm: Farm
}

export const getPoolContractByFarm = (opts: GetContractOpts) => {
  const { provider, farm } = opts
  const web3 = new Web3(provider)
  if (isReinvest2(farm))
    return new web3.eth.Contract(
      (ReinvestABI as unknown) as AbiItem,
      farm.poolAddress,
    )
  if (isV3Pool(farm)) {
    return new web3.eth.Contract(
      (V3ABI as unknown) as AbiItem,
      farm.poolAddress,
    )

  }
  return new web3.eth.Contract(
    (PoolABI as unknown) as AbiItem,
    farm.poolAddress,
  )
}

//deprecated
export const getContract = (provider: provider, address: string, pool?: string) => {
  const web3 = new Web3(provider)
  if (pool && pool === 'reinvest2')
    return new web3.eth.Contract(
      (ReinvestABI as unknown) as AbiItem,
      address,
    )
  else
    return new web3.eth.Contract(
      (PoolABI as unknown) as AbiItem,
      address,
    )

}

export async function getPoolStaked(eth: provider, account: string, pool_addr: string) {
  const contract = getContract(eth, pool_addr)
  const balance = await contract.methods.balanceOf(account).call();
  return balance
}
