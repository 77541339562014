
import { EDCServiceSet, PoolsConfig, PoolsRequiredConfig } from "./types"

const service: EDCServiceSet = {
    earned1: { method: "pendingReward", },
    staked1: { method: "userInfo", },
    deposit: { method: "deposit", },
    withdraw: { method: "withdraw", },
    exit: { method: "withdrawAll", },
    harvest: { method: "withdraw", },
    maxBalance: { method: "poolInfo" },
    apy: { method: "getApy", },
}

const cfg: PoolsRequiredConfig[] = [
    {
        poolAddresses: {
            128: "0x80b0eAfA5AAec24c3971d55A4919e8D6a6b71c78",
        },
        stakingTokenAddresses: {
            128: "0xef3cebd77e0c52cb6f60875d9306397b5caca375",
        },
        name: "BCH",
        icon: "bch",
        contract_pid: 6,
    },
    {
        poolAddresses: {
            128: "0x80b0eAfA5AAec24c3971d55A4919e8D6a6b71c78",
        },
        stakingTokenAddresses: {
            128: "0xe499ef4616993730ced0f31fa2703b92b50bb536",
        },
        name: "HPT",
        icon: "hpt",
        contract_pid: 7,
    },
    {
        poolAddresses: {
            128: "0x80b0eAfA5AAec24c3971d55A4919e8D6a6b71c78",
        },
        stakingTokenAddresses: {
            128: "0xae3a768f9ab104c69a7cd6041fe16ffa235d1810",
        },
        name: "FIL",
        icon: "fil",
        contract_pid: 8,
    },
    {
        poolAddresses: {
            128: "0x80b0eAfA5AAec24c3971d55A4919e8D6a6b71c78",
        },
        stakingTokenAddresses: {
            128: "0xa2c49cee16a5e5bdefde931107dc1fae9f7773e3",
        },
        name: "DOT",
        icon: "dot",
        contract_pid: 9,
    },
    {
        poolAddresses: {
            128: "0x80b0eAfA5AAec24c3971d55A4919e8D6a6b71c78",
        },
        stakingTokenAddresses: {
            128: "0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c",
        },
        name: "MDX",
        icon: "MDX",
        decimal: 18,
        contract_pid: 5,
    },
    {
        poolAddresses: {
            128: "0x80b0eAfA5AAec24c3971d55A4919e8D6a6b71c78",
        },
        stakingTokenAddresses: {
            128: "0x66a79D23E58475D2738179Ca52cd0b41d73f0BEa",
        },
        name: "BTC",
        icon: "hbtc",
        contract_pid: 0
    },
    {

        poolAddresses: {
            128: "0x80b0eAfA5AAec24c3971d55A4919e8D6a6b71c78",
        },
        stakingTokenAddresses: {
            128: "0x64FF637fB478863B7468bc97D30a5bF3A428a1fD",
        },
        name: "ETH",
        icon: "eth",
        contract_pid: 1
    },
    {

        poolAddresses: {
            128: "0x80b0eAfA5AAec24c3971d55A4919e8D6a6b71c78",
        },
        stakingTokenAddresses: {
            128: "0xa71EdC38d189767582C38A3145b5873052c3e47a",
        },
        name: "USDT",
        icon: "usdt",
        contract_pid: 2
    },
    {
        poolAddresses: {
            128: "0x80b0eAfA5AAec24c3971d55A4919e8D6a6b71c78",
        },
        stakingTokenAddresses: {
            128: "0x0298c2b32eaE4da002a15f36fdf7615BEa3DA047",
        },
        name: "HUSD",
        icon: "husd",
        contract_pid: 3,
        decimal: 8,

    },
    {
        poolAddresses: {
            128: "0x80b0eAfA5AAec24c3971d55A4919e8D6a6b71c78",
        },
        stakingTokenAddresses: {
            128: "0x5545153CCFcA01fbd7Dd11C0b23ba694D9509A6F",
        },
        name: "WHT",
        icon: "ht",
        contract_pid: 4,
    }

]

const v3Pool = cfg.map<PoolsConfig>((e) => ({
    ...e,
    page: "v3",
    symbol: e.name,
    tokenSymbol: e.name,
    decimal: e.decimal ?? 18,
    isWBNB: e.isWBNB ?? false,
    magnification: e.magnification ?? 4,
    earnToken: e?.earnToken ?? "EDC",
    earnDecimal: e.earnDecimal ?? 9,
    apyName: "husdht",
    service,
}));
export default v3Pool
