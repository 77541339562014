import React, { useCallback, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { UseWalletProvider } from "use-wallet";
import DisclaimerModal from "./components/DisclaimerModal";
import TopBar from "./components/TopBar";
import FarmsProvider from "./contexts/Farms";
import ModalsProvider from "./contexts/Modals";
import TransactionProvider from "./contexts/Transactions";
import AcceleratorsProvider from "./contexts/Accelerators";
import useModal from "./hooks/useModal";
import theme from "./theme";
import Farms from "./views/Farms";
import Home from "./views/Home";
import "./i18n/config";
import { useAutoConnect } from "./libs/autoconnect";
import { Provider, useDispatch } from "react-redux";
import { initializeStore } from "./libs/state/store";
import Note from "./components/Note/";
import { ActSetState } from "./libs/state/global";
import useBlockUpdater from "./hooks/useBlock";
import BlockNumber from "./components/BlockNumber/BlockNumber";



const store = initializeStore();
const Content = () => {
  const dispatch = useDispatch()
  useAutoConnect();
  useBlockUpdater()

  const location = useLocation()
  useEffect(() => {
    dispatch(ActSetState({ showMenu: false }))

  }, [ location])



  return (
    <>

      <BlockNumber />
      <TopBar />

      <Switch>

        <Route path="/index" exact>
          <Home />
        </Route>

        <Route path="/" exact>
          <Farms page={"v3"} />
        </Route>
        <Route path="/v3">
          <Farms page={"v3"} />
        </Route>
        <Route path="/farms">
          <Farms page={"farms"} />
        </Route>
        <Route path="/vault">
          <Farms page={"vault"} />
        </Route>
        <Route path="/lpvault">
          <Farms page={"lpvault"} />
        </Route>
        <Route path="/vault2">
          <Farms page={"vault2"} />
        </Route>
        <Route path="/reinvest2">
          <Farms page={"reinvest2"} />
        </Route>
        <Route path="/more" exact>
          <Note />
        </Route>
      </Switch>


    </>
  );
};

const App: React.FC = () => {
  return (
    <Providers>
      <Content />
      <Disclaimer />
    </Providers>
  );
};

const Providers: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <UseWalletProvider
          chainId={128}
          connectors={{
            walletconnect: {
              rpcUrl:
                "https://mainnet.infura.io/v3/1ca6d03c37aa427a865f3ac6ab23c09a",
            },
          }}
        >
          <TransactionProvider>
            <FarmsProvider>
              <AcceleratorsProvider>
                <ModalsProvider>
                  <Router>
                    {children}
                  </Router>
                </ModalsProvider>
              </AcceleratorsProvider>
            </FarmsProvider>
          </TransactionProvider>
        </UseWalletProvider>
      </Provider>
    </ThemeProvider>
  );
};

const Disclaimer: React.FC = () => {
  const markSeen = useCallback(() => {
    localStorage.setItem("disclaimer", "seen");
  }, []);

  const [onPresentDisclaimerModal] = useModal(
    <DisclaimerModal onConfirm={markSeen} />
  );

  useEffect(() => {
    const seenDisclaimer = true; // localStorage.getItem('disclaimer')
    if (!seenDisclaimer) {
      onPresentDisclaimerModal();
    }
  }, [onPresentDisclaimerModal]);

  return <div />;
};

export default App;
