import { EDCServiceSet } from "../../constants/pools/types";

export type FarmPageChoices = "farms" | "vault" | "lpvault" | "vault2" | "reinvest2" | "v3";
export enum FarmTypeEnum {
  FarmStaking = "farms",
  FarmVault = "vault",
  FarmLPVault = "lpvault",
  FarmVault2 = "vault2",
  FarmReinvest2 = "reinvest2",
  FarmVault3 = "valult3",
}
export function isV3Pool(farm: Farm) {
  return farm.page === 'v3'
}

export function isReinvest2(farm: Farm) {
  return farm.page === 'reinvest2'
}

export function isMdexLP(farm: Farm) {
  return farm.name === "MDEX EDC-USDT LP"

}
export function isLPVault(farm: Farm) {
  return farm.page === 'lpvault'
}

export function isFarm(farm: Farm) {
  return farm.page === 'farms'
}

export function isVault(farm: Farm) {
  return farm.page === 'vault'
}

export function isHUSD(farm: Farm) {
  return farm.earnToken === "HUSD" || (farm as any).earnToken === "HUSD";
}
export interface Farm {
  pid: number;
  name: string;
  poolAddress: string;
  decimal: number;
  stakingToken: string;
  stakingTokenAddress: string;

  earnToken: string;
  earnTokenAddress: string;
  earnDecimal: number;

  id: string;
  tokenSymbol: string;
  isWBNB: boolean;
  icon: string;
  magnification: number;
  apyName: string;
  page: FarmPageChoices;
  service: EDCServiceSet
  contract_pid: number

}

export interface FarmsContext {
  farms: Farm[];
}
