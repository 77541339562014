import React from "react";
import styled from "styled-components";
import SvgWave from "./assets/wave.svg";
import SvgBigWave from "./assets/bigwave.svg";

const StyledModalBgContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;
const WaveContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 60px;
  left: 0;
  bottom: 0;
  overflow: hidden;
  img {
    width: 100%;
  }
`;
const BigWaveContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const ModalBg: React.FC<{ variant?: "ready" | "loading" }> = ({ variant }) => {
  const LoadingBg = (
    <StyledModalBgContainer>
      <BigWaveContainer>
        <img src={SvgBigWave} alt={"icon"}/>
      </BigWaveContainer>
    </StyledModalBgContainer>
  );
  const ReadyBg = (
    <StyledModalBgContainer>
      <WaveContainer>
        <img src={SvgWave} alt={"icon"}/>
      </WaveContainer>
    </StyledModalBgContainer>
  );
  switch (variant) {
    case "loading":
      return LoadingBg;
    case "ready":
      return ReadyBg;
    default:
      return ReadyBg;
  }
};

export default ModalBg;
