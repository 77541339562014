import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: require('./en/en.json')
      },
      zh_cn: {
        translation: require('./zhcn/zh.json')
      }
    },
    lng: "zh_cn",
    fallbackLng: "zh_cn",

    interpolation: {
      escapeValue: false
    }
  })