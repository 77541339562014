import { EDCServiceSet, PoolsConfig, PoolsRequiredConfig } from "./types"

const cfg: PoolsRequiredConfig[] = [
    {
        poolAddresses: {
            128: "0x44029AC7D09Baf6605E10E24329CF241aF15637F",
        },
        stakingTokenAddresses: {
            128: "0x8764Bd4fcc027faF72bA83c0b2028a3BAE0D2D57",
        },
        name: "HBO",
        symbol: "HBO",
        tokenSymbol: "HBO",
        earnToken: "HBO",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0xe7BD08143fF0Ef59a5c32545760990F54601985C",
        },
        stakingTokenAddresses: {
            128: "0xc189C6c138e78E8A4c1f1633E4C402e0C49a6049",
        },
        name: "HBO/USDT",
        symbol: "HBO/USDT",
        tokenSymbol: "HBO/USDT",
        earnToken: "HBO/USDT",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x3C71c42ACf5609e93A43068F6c5F49C7955Aa9E8",
        },
        stakingTokenAddresses: {
            128: "0x2EB1eFe826C462105ADf724F8307416D605e40A7",
        },
        name: "HBO/HUSD",
        symbol: "HBO/HUSD",
        tokenSymbol: "HBO/HUSD",
        earnToken: "HBO/HUSD",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0xC35Bc0C5038B378993498d7C90BBa27124e0daEe",
        },
        stakingTokenAddresses: {
            128: "0xa6e787C0eFC68c9A400db05e7764ff2d224C4F6A",
        },
        name: "HBO/BTC",
        symbol: "HBO/BTC",
        tokenSymbol: "HBO/BTC",
        earnToken: "HBO/BTC",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0xC387E156B51a1733BebFd6a1bcc16f986976D9b9",
        },
        stakingTokenAddresses: {
            128: "0x235D09d666FB95EA422Ef8d3523936A6140b20bb",
        },
        name: "HBO/HT",
        symbol: "HBO/HT",
        tokenSymbol: "HBO/HT",
        earnToken: "HBO/HT",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },


    {
        poolAddresses: {
            128: "0x94E9D81494220d69399b72cb3945361d8758c709",
        },
        stakingTokenAddresses: {
            128: "0x7E8af53Fe202B80bB0CE383e1011A9fFB2662540",
        },
        name: "LLC/USDT",
        symbol: "LLC/USDT",
        tokenSymbol: "LLC/USDT",
        earnToken: "LLC/USDT",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x7EC9CD0066e7B579df053E459aAdd8aB9477Ab54",
        },
        stakingTokenAddresses: {
            128: "0x615E6285c5944540fd8bd921c9c8c56739Fd1E13",
        },
        name: "MDX/USDT",
        symbol: "MDX/USDT",
        tokenSymbol: "MDX/USDT",
        earnToken: "MDX/USDT",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x3B7437E1A43238527681acfD337213DC8982EDae",
        },
        stakingTokenAddresses: {
            128: "0x6Dd2993B50b365c707718b0807fC4e344c072eC2",
        },
        name: "MDX/WHT",
        symbol: "MDX/WHT",
        tokenSymbol: "MDX/WHT",
        earnToken: "MDX/WHT",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x6Ea2d2f9b35bC0341E5a7BAEfd1F7a57f2695A4f",
        },
        stakingTokenAddresses: {
            128: "0x7964E55BBdAECdE48c2C8ef86E433eD47FEcB519",
        },
        name: "FILDA/HUSD",
        symbol: "FILDA/HUSD",
        tokenSymbol: "FILDA/HUSD",
        earnToken: "FILDA/HUSD",
        earnDecimal: 12,
        magnification: 1,
        decimal: 12,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x9F4f76D9DCbe926d7907A1EC501Db1b48fe5452E",
        },
        stakingTokenAddresses: {
            128: "0x55542f696a3fEcaE1C937Bd2e777B130587cFD2d",
        },
        name: "FILDA/HT",
        symbol: "FILDA/HT",
        tokenSymbol: "FILDA/HT",
        earnToken: "FILDA/HT",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },

    {
        poolAddresses: {
            128: "0x9F0189FC90E6371F75f5840cc91EC7a889c1596E",
        },
        stakingTokenAddresses: {
            128: "0xdDE0D948B0597F08878620f1Afd3070dC7243386",
        },
        name: "BAG/HUSD",
        symbol: "BAG/HUSD",
        tokenSymbol: "BAG/HUSD",
        earnToken: "BAG/HUSD",
        earnDecimal: 14,
        magnification: 1,
        decimal: 14,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x9528b23cAAd681172614591B47f7619c4e55b79f",
        },
        stakingTokenAddresses: {
            128: "0xFBe7b74623e4be82279027a286fa3A5b5280F77c",
        },
        name: "BTC/USDT",
        symbol: "BTC/USDT",
        tokenSymbol: "BTC/USDT",
        earnToken: "BTC/USDT",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0xfC506ff16D4452F7477892BB7dd6fA1e5D6f518A",
        },
        stakingTokenAddresses: {
            128: "0x78C90d3f8A64474982417cDB490E840c01E516D4",
        },
        name: "ETH/USDT",
        symbol: "ETH/USDT",
        tokenSymbol: "ETH/USDT",
        earnToken: "ETH/USDT",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x5bE43a3fddAF1C0c954ee25eE9266860184A9D82",
        },
        stakingTokenAddresses: {
            128: "0xdE5b574925EE475c41b99a7591EC43E92dCD2fc1",
        },
        name: "HPT/USDT",
        symbol: "HPT/USDT",
        tokenSymbol: "HPT/USDT",
        earnToken: "HPT/USDT",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0xF58fEda04d48ea081777c02F2fb84d3C34d515E7",
        },
        stakingTokenAddresses: {
            128: "0xdff86B408284dff30A7CAD7688fEdB465734501C",
        },
        name: "HUSD/USDT",
        symbol: "HUSD/USDT",
        tokenSymbol: "HUSD/USDT",
        earnToken: "HUSD/USDT",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x678c46c26bD599203015f70495998Ca490127da6",
        },
        stakingTokenAddresses: {
            128: "0x499B6E03749B4bAF95F9E70EeD5355b138EA6C31",
        },
        name: "WHT/USDT",
        symbol: "WHT/USDT",
        tokenSymbol: "WHT/USDT",
        earnToken: "WHT/USDT",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x1C8373F3f1054aabf7A1A534EE6a671d5A623F3f",
        },
        stakingTokenAddresses: {
            128: "0x1f0eC8e0096e145f2bf2Cb4950Ed7b52d1cbd35f",
        },
        name: "BCH/USDT",
        symbol: "BCH/USDT",
        tokenSymbol: "BCH/USDT",
        earnToken: "BCH/USDT",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0xf5d0E3f45B64396366a9271c1DDFa6085813959A",
        },
        stakingTokenAddresses: {
            128: "0xBFff969A85e355eE0851b019Dba1e87C7780F40d",
        },
        name: "BTC/HT",
        symbol: "BTC/HT",
        tokenSymbol: "BTC/HT",
        earnToken: "BTC/HT",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x34ba1F0e6ACA591BBF95DfcaBA50060fa3b23Cd9",
        },
        stakingTokenAddresses: {
            128: "0x5484ab0DF3E51187f83F7f6b1a13f7a7Ee98C368",
        },
        name: "DOT/USDT",
        symbol: "DOT/USDT",
        tokenSymbol: "DOT/USDT",
        earnToken: "DOT/USDT",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0xAa3347DE3297382CAc3740bBcfA0De9ebF13841C",
        },
        stakingTokenAddresses: {
            128: "0x793c2a814e23EE38aB46412Be65E94Fe47D4B397",
        },
        name: "ETH/BTC",
        symbol: "ETH/BTC",
        tokenSymbol: "ETH/BTC",
        earnToken: "ETH/BTC",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0xab0DB0498abFf8E18393705aDc9F76f05BEf9583",
        },
        stakingTokenAddresses: {
            128: "0x53E458aD1CFEB9582736db6BdE9aF89948e3bc3d",
        },
        name: "ETH/HT",
        symbol: "ETH/HT",
        tokenSymbol: "ETH/HT",
        earnToken: "ETH/HT",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0xa3749DA8561e7F6efb5e7210A296Fbb0181EB185",
        },
        stakingTokenAddresses: {
            128: "0x600072aF0470d9Ed1D83885D03d17368943fF22A",
        },
        name: "FIL/USDT",
        symbol: "FIL/USDT",
        tokenSymbol: "FIL/USDT",
        earnToken: "FIL/USDT",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0xcA535813E13d52Bf7CAa539A664743166d5626C1",
        },
        stakingTokenAddresses: {
            128: "0x060B4bfcE16D15A943ec83C56C87940613e162eB",
        },
        name: "LTC/USDT",
        symbol: "LTC/USDT",
        tokenSymbol: "LTC/USDT",
        earnToken: "LTC/USDT",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x15Aa44b32825455b14f60c3e4fa85F5F74daAB15",
        },
        stakingTokenAddresses: {
            128: "0x3375afF2CAcF683b8FC34807B9443EB32e7Afff6",
        },
        name: "WHT/HUSD",
        symbol: "WHT/HUSD",
        tokenSymbol: "WHT/HUSD",
        earnToken: "WHT/HUSD",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x59e76F6B18E9DD6d33a7642C4Ca6C38a91b0626d",
        },
        stakingTokenAddresses: {
            128: "0xCf9Bb6F88c5B6dDb5c067a0C6d6Ae872f895B033",
        },
        name: "SLNV2/USDT",
        symbol: "SLNV2/USDT",
        tokenSymbol: "SLNV2/USDT",
        earnToken: "SLNV2/USDT",
        earnDecimal: 18,
        magnification: 1,
        decimal: 18,
        icon: "dh",
    },
]

const service: EDCServiceSet = {
    earned1: { method: "earned2", },
    staked1: { method: "balanceOf", },
    staked2: { method: "earned", },
    deposit: { method: "stake", },
    withdraw: { method: "getReward", },
    exit: { method: "exit", },
    harvest: { method: "getReward2", },
    apy: {
        method: "",
        rest: { endpoint: "/api/apy/" }
    }
}

const lpvaultPool = cfg.map<PoolsConfig>((e) => ({
    ...e,
    page: "lpvault",
    symbol: e.name,
    tokenSymbol: e.name,
    decimal: e.decimal ?? 18,
    isWBNB: e.isWBNB ?? false,
    magnification: e.magnification ?? 4,
    earnToken: e.earnToken ?? "HUSD",
    earnDecimal: e.earnDecimal ?? 8,
    apyName: "husdht",
    service
}))

export default lpvaultPool