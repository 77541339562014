import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import NavData from "../NavData";
import { useTypedSelector } from "../../../libs/state/store";
import { useDispatch } from "react-redux";
import { ActSetState } from "../../../libs/state/global";

const Nav: React.FC = () => {
  const { t } = useTranslation();
  const show = useTypedSelector(e => e.GlobalReducers.showMenu)
  const dispatch = useDispatch()
  return (
    <>
      <StyledNav show={show}>
        {
          NavData.map((e, i) => (
            e?.out ?
              <StyledAbsoluteLink
                key={i}
                href={e.route}
                target="_blank"
              >
                {t(e.key)}
              </StyledAbsoluteLink> :
              <StyledLink exact activeClassName="active" to={e.route} key={i}>
                {t(e.key)}
              </StyledLink>
          ))
        }


      </StyledNav>
      {show ?
        <Mask onClick={() => dispatch(ActSetState({ showMenu: false }))} /> : null
      }
    </>
  );
};

const Mask = styled.div`
  @media (min-width:768px){
    display: none;
  }
  @media (max-width:356px){
    top:0;
  }
  position:fixed;
  top:60px;
  left:0;
  width: 100vw;
  height: 100vh;
  z-index:100;
  background:rgba(237,182,5,0.1);
`

const StyledNav = styled.nav<{ show?: boolean }>`
  align-items: center;
  display: flex;

  @media (max-width:768px){
    z-index:101;
    display: ${p => p.show ? "flex" : "none"};
    position: fixed;
    top: 60px;
    right:0;
    flex-direction:column;
    background: #171923;
    width: 50%;
    height: 100vh;
  }

  @media (max-width: 356px){
    top:0;

  }

`;

const StyledLink = styled(NavLink)`
  color: #737375;
  line-height:50px;
  &:hover {
    color: ${(props) => props.theme.color.yellow};
    font-weight: 500;
  }
  &.active {
    color: ${(props) => props.theme.color.yellow};
    font-weight: 500;
  }
    font-size: 14px;
    font-weight: 400;
    padding-left: ${(props) => props.theme.spacing[3]}px;
    padding-right: ${(props) => props.theme.spacing[3]}px;
    text-decoration: none;


`;

const StyledAbsoluteLink = styled.a`
  color: #737375;
  text-decoration: none;
    font-weight: normal;
    padding-left: ${(props) => props.theme.spacing[3]}px;
    padding-right: ${(props) => props.theme.spacing[3]}px;
    text-decoration: none;
    line-height: 50px;
    &:hover {
      color: ${(props) => props.theme.color.yellow};
      font-weight: 500;
      height: 20px;
      line-height: 20px;
    }
    &.active {
      color: ${(props) => props.theme.color.yellow};
      font-weight: bold;
      font-weight: 500;
      height: 20px;
      line-height: 20px;
    }
`;

export default Nav;
