import { EDCServiceSet, PoolsConfig, PoolsRequiredConfig } from "./types";

const service: EDCServiceSet = {
    earned1: { method: "earned", },
    earned2: { method: "earned2", },
    staked1: { method: "balanceOf", },
    deposit: { method: "stake", },
    withdraw: { method: "withdraw", },
    exit: { method: "exit", },
    harvest: { method: "getReward", },
    apy: {
        method: "",
        rest: { endpoint: "/api/apy/" }
    }
}
const cfg: PoolsRequiredConfig[] = [
    {
        poolAddresses: {
            128: "0x3416d0CDBf925E69280555da8e714706f278770f",
        },
        stakingTokenAddresses: {
            128: "0x68a0A1fEF18DfCC422Db8bE6F0F486dEa1999EDC",
        },
        name: "EDC",
        symbol: "EDC",
        tokenSymbol: "EDC",
        magnification: 20,
        decimal: 9,
        icon: "edc",
        earnToken: "HT",
        earnDecimal: 18
    },

    {
        poolAddresses: {
            128: "0x1C2811d2785DB0cfc56938121d2389157C242F08",
        },
        stakingTokenAddresses: {
            128: "0xfEd180001c0A85a0B6d4559be5cE63330e7Ca9b6",
        },
        name: "MDEX EDC-USDT LP",
        symbol: "MDEX EDC-USDT LP",
        tokenSymbol: "MDEX EDC-USDT LP",
        magnification: 20,
        decimal: 12,
        icon: "dh",
        earnToken: "HT + EDC"
    },
    {
        poolAddresses: {
            128: "0x5BFdb7BAa821A571024F1Bf02a7a172046EcfA94",
        },
        stakingTokenAddresses: {
            128: "0xd83fe567c089ec6b7984cc0480d44455b2c88980",
        },
        name: "EDC-HUSD LP",
        symbol: "EDC-HUSD LP",
        tokenSymbol: "EDC-HUSD LP",
        magnification: 20,
        decimal: 8,
        icon: "dh",
    },
    {
        poolAddresses: {
            128: "0x7F4ccc51b9e851798596f69963E5D77a55838044",
        },
        stakingTokenAddresses: {
            128: "0x1e6395e6b059fc97a4dda925b6c5ebf19e05c69f",
        },
        name: "CAN",
        symbol: "CAN",
        tokenSymbol: "CAN",
        icon: "can",
        decimal: 18,
        magnification: 4,
    },
    {
        poolAddresses: {
            128: "0x5f8674e357422348094c9cd1cdc4215edb18a185",
        },
        stakingTokenAddresses: {
            128: "0x0000000000000000000000000000000000000000",
        },
        name: "HT",
        symbol: "HT",
        tokenSymbol: "HT",
        isWBNB: true,
        icon: "ht",
        decimal: 18,
        magnification: 4,
    },
    {
        poolAddresses: {
            128: "0xe720e61358abd2b290f04c3d59ac224e07bb7834",
        },
        stakingTokenAddresses: {
            128: "0x0298c2b32eae4da002a15f36fdf7615bea3da047",
        },
        name: "HUSD",
        symbol: "HUSD",
        tokenSymbol: "HUSD",
        icon: "husd",
        decimal: 8,
        magnification: 4,
    },
    {
        poolAddresses: {
            128: "0x130b1db74b40ce900e58b9e850c5fa907e42ac47",
        },
        stakingTokenAddresses: {
            128: "0x64ff637fb478863b7468bc97d30a5bf3a428a1fd",
        },
        decimal: 18,
        magnification: 4,
        name: "ETH",
        symbol: "ETH",
        tokenSymbol: "ETH",
        icon: "eth",
    },
    {
        poolAddresses: {
            128: "0xd2fe49d2409597ddd6d6d3757384292f2e4b84a0",
        },
        stakingTokenAddresses: {
            128: "0x66a79d23e58475d2738179ca52cd0b41d73f0bea",
        },
        name: "BTC",
        symbol: "BTC",
        tokenSymbol: "BTC",
        icon: "hbtc",
        decimal: 18,
        magnification: 4,
    },
    {
        poolAddresses: {
            128: "0xcd0B0659F4eF292EFFD29fE2acd6130126A3c9a3",
        },
        stakingTokenAddresses: {
            128: "0xe499ef4616993730ced0f31fa2703b92b50bb536",
        },
        name: "HPT",
        symbol: "HPT",
        tokenSymbol: "HPT",
        icon: "hpt",
        decimal: 18,
        magnification: 4,
    },
    {
        poolAddresses: {
            128: "0xc78c8fd6c97413e27376f4183b8d3995bf72a8bd",
        },
        stakingTokenAddresses: {
            128: "0xc036fb5687a8c0ab438faa5c744b55fd705a0c56",
        },
        name: "HQTUM",
        symbol: "HQTUM",
        tokenSymbol: "HQTUM",
        icon: "hqtum",
        decimal: 8,
        magnification: 4,
    },
]
const stakePool = cfg.map<PoolsConfig>((e) => ({
    ...e,
    page: "farms",
    symbol: e.name,
    tokenSymbol: e.name,
    decimal: e.decimal ?? 18,
    magnification: e.magnification ?? 4,
    isWBNB: e.isWBNB ?? false,
    earnToken: e.earnToken ?? "EDC",
    earnDecimal: e.earnDecimal ?? 9,
    apyName: "husdht",
    service
}));
export default stakePool