import lpvaultPool from "./pools/lpvault";
import reinvest2Pool from "./pools/reinvest2";
import stakePool from "./pools/stake";
import v3Pool from "./pools/v3";
import vaultPool from "./pools/vault";
import vault2Pool from "./pools/vaultv2";

export const Pools = [
  ...stakePool,
  ...vaultPool,
  ...lpvaultPool,
  ...vault2Pool,
  ...reinvest2Pool,
  ...v3Pool,
];
