import { provider } from "web3-core";
import BigNumber from "bignumber.js";
import { Farm } from "../contexts/Farms"
import { FarmTypeEnum, isReinvest2, isV3Pool } from "../contexts/Farms/types"
import { useWallet } from "use-wallet";
import { useCallback, useEffect, useMemo } from "react";
import { Contract, ContractSendMethod } from 'web3-eth-contract'
import { getPoolContractByFarm } from "../utils/pool";
import { useDispatch } from "react-redux";
import { ActUpdatePoolData } from "../libs/state/pools";

import { EDCSvcKeyChoices } from "../constants/pools/types";
import axios from "axios";
import { useTypedSelector } from "../libs/state/store";

export interface CardInfo {
    type: FarmTypeEnum,
    earned1: BigNumber,
    earned2?: BigNumber,
    staked1: BigNumber,
    staked2?: BigNumber,
}



type CallOpts = {
    contract: Contract,
    account: string,
    method: string,
    parameters: any[],
}

export function GetBalanceof(farm:Farm,staked1:BigNumber,staked2:BigNumber){
    if (farm.service.staked1.method==="balanceOf" || isV3Pool(farm)){
        return staked1
    }
    return staked2
}

export async function CallContractMethods(opts: CallOpts) {
    const { contract, method, parameters, account } = opts
    return new Promise<any>(async (resolve, reject) => {
        try {
            const tm: ContractSendMethod = contract
                .methods[method](...parameters)

            const res = await
                tm.call({ from: account, gas: 3000000 })

            resolve(res)
        } catch (e) {
            reject(e)
        }
    })
}

const useUserStaked1 = (ctx: {
    account: string
    farm: Farm,
    contract: Contract

}) => {

    const { account, farm, contract } = ctx
    const getUserStaked1 = useCallback(async () => {
        if (!account)
            return undefined
        const method = farm.service.staked1.method
        const callctx: InfoParameterGenCtx = {
            account,
            farm,
            method
        }
        const res = await CallContractMethods({
            account, contract, method, parameters: getInfoParameters(callctx)
        })

        if (isV3Pool(farm)) {
            return new BigNumber(res[0])
        }
        return new BigNumber(res)
    }, [account, contract, farm])


    return { getUserStaked1 }

}
const useGetNav = (farm: Farm) => {
    const { ethereum, account } = useWallet();
    const contract = useMemo(() => {
        return getPoolContractByFarm({
            provider: ethereum as provider,
            farm
        })
    }, [farm, ethereum])

    const getNAV = useCallback(async () => {
        if (isReinvest2(farm)) {
            const ratio = await CallContractMethods({
                account, contract,
                method: "getPricePerFullShare",
                parameters: []
            })
            return new BigNumber(ratio)
        }
        return new BigNumber(0)
    }, [account, contract, farm])
    return { getNAV }
}
interface InfoParameterGenCtx {
    account: string,
    method: string
    farm: Farm,
    contractID?: string
}
const getInfoParameters = ({
    account, method, farm
}: InfoParameterGenCtx) => {
    if (method === "getWantBalance" ||
        method === "earned2" ||
        method === "balanceOf" ||
        method === "earned"
    )
        return [account]
    if (
        method === "pendingReward" ||
        method === "userInfo"
    )
        return [farm.contract_pid, account]
}

export const useCardInfoUpdater: (farm: Farm) => void = (farm) => {
    const { ethereum, account } = useWallet();
    const dispatch = useDispatch()
    const latestBlock = useTypedSelector(e => e.GlobalReducers.latestBlockNumber)

    const contract = useMemo(() => {
        return getPoolContractByFarm({
            provider: ethereum as provider,
            farm
        })
    }, [farm, ethereum])

    const getAPY = useCallback(async () => {
        if (!account || !contract)
            return
        if (farm.service.apy.rest) {
            const res = await axios
                .get("https://api.earndefi.finance/apy/" + farm.poolAddress)
            return res.data
        }
        else {

            const res = await CallContractMethods({
                account,
                contract,
                method: farm.service.apy.method,
                parameters: [farm.contract_pid]
            })

            return (new BigNumber(res)).dividedBy(new BigNumber("1e16")).toFixed(2) + "%"
        }
    }, [account, contract, farm])

    const getTotalStaked = useCallback(async () => {
        if (isV3Pool(farm)) {
            const res = await CallContractMethods({
                account,
                contract,
                method: "poolInfo",
                parameters: [farm.contract_pid]
            })
            return new BigNumber(res[4])
        }
        return new BigNumber(await CallContractMethods({
            account,
            contract,
            method: "totalSupply", parameters: []
        }))
    }, [account, contract, farm])


    const { getUserStaked1 } = useUserStaked1({ account, contract, farm })
    const { getNAV } = useGetNav(farm)


    useEffect(() => {
        getNAV().then(
            nav => {
                return dispatch(ActUpdatePoolData({
                    [farm.pid]: { nav }
                }));
            }
        )
            .catch(e => {
                console.log(`getNavError`, e)
            })
    }, [ farm.pid, getNAV, latestBlock])

    useEffect(() => {
        getAPY().then(
            apy => dispatch(ActUpdatePoolData({
                [farm.pid]: { apy }
            }))
        )
            .catch(e => {
                console.log(`getApyError`, e)
            })
    }, [ farm.pid, getAPY, latestBlock])

    useEffect(() => {
        getUserStaked1().then(
            total => dispatch(ActUpdatePoolData({
                [farm.pid]: { staked1: total ?? new BigNumber(0) }
            }))
        )
            .catch(e => {
                console.log(`getUserStaked Error`, e)
            })
    }, [ farm.pid, getUserStaked1, latestBlock])

    useEffect(() => {
        getTotalStaked().then(
            total => dispatch(ActUpdatePoolData({
                [farm.pid]: { total }
            }))
        ).catch(e => {
            console.log(`getTotalStakedError`, e)
        })
    }, [ farm.pid, getTotalStaked, latestBlock])

    useEffect(() => {

        const method = farm.service?.maxBalance?.method
        method && account && contract && CallContractMethods({
            contract, account, method,
            parameters: [farm.contract_pid],
        }).then(e => {
            dispatch(ActUpdatePoolData({
                [farm.pid]: { maxBalance: new BigNumber(e[5]) }
            }))
        })

    }, [account, contract, farm.contract_pid, farm.pid, farm.service, latestBlock])

}

export const usePoolInfoUpdater: (farm: Farm) => void = (farm: Farm) => {

    const { ethereum, account } = useWallet();
    const dispatch = useDispatch()
    const latestBlock = useTypedSelector(e => e.GlobalReducers.latestBlockNumber)

    const contract = useMemo(() => {
        return getPoolContractByFarm({
            provider: ethereum as provider,
            farm
        })
    }, [farm, ethereum])

    const { getUserStaked1 } = useUserStaked1({ account, contract, farm })

    const UpdateBasicInfo = useCallback(() => {
        if (!account) return
        let method: string = "";
        const MOI: EDCSvcKeyChoices[] = ["earned1", "earned2", "staked2"]

        //general
        for (let mname of MOI) {
            method = farm.service?.[mname]?.method
            const ctx: InfoParameterGenCtx = {
                account,
                method,
                farm
            }
            method && account && CallContractMethods({
                contract,
                account,
                method: method,
                parameters: getInfoParameters(ctx)
            })
                .then((e) => dispatch(ActUpdatePoolData({
                    [farm.pid]: { [mname]: new BigNumber(e) }
                })))
        }

        //staked
        getUserStaked1().then(
            (e) => dispatch(ActUpdatePoolData({
                [farm.pid]: { 'staked1': new BigNumber(e) }
            }))

        )
    }, [account, getUserStaked1, farm, contract])


    useEffect(() => {
        UpdateBasicInfo()
    }, [UpdateBasicInfo, latestBlock])

}