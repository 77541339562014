import React from "react";
import styled from "styled-components";
import Container from "../Container";
import Logo from "../Logo";
import SvgCollapsed from './components/assets/collapse.svg'
import SvgOpen from './components/assets/open.svg'
import AccountButton from "./components/AccountButton";
import Nav from "./components/Nav";

import ScrollInfoBar from "../ScrollInfoBar";
import { useTypedSelector } from "../../libs/state/store";
import { useDispatch } from "react-redux";
import { ActSetState } from "../../libs/state/global";

interface TopBarProps {
}

const TopBar: React.FC<TopBarProps> = () => {
  const dispatch = useDispatch()
  const show = useTypedSelector(e => e.GlobalReducers.showMenu)
  return (
    <>
      <StyledTopBar>
        <Container size="lg">
          <StyledTopBarInner>
            <One>
              <StyledLogoWrapper>
                <Logo />
              </StyledLogoWrapper>
              <StyledNavWrapper>
                <Nav />
              </StyledNavWrapper>
            </One>
            <Two>
              <StyledAccountButtonWrapper>
                <AccountButton />
                <IconCollapsed src={show ? SvgCollapsed : SvgOpen} onClick={() => dispatch(ActSetState({
                  showMenu: !show
                }))} />
              </StyledAccountButtonWrapper>
            </Two>
          </StyledTopBarInner>
        </Container>
      </StyledTopBar>
      <Notice>
        <ScrollInfoBar />
      </Notice>

    </>
  );
};

const IconCollapsed = styled.img`
  cursor: pointer;
  @media screen and (min-width:768px){
    display:none;
  }
  height: 20px;
  margin-right:20px;
`
const StyledLogoWrapper = styled.div`
   margin-top:5px;
`;

const StyledTopBar = styled.div`
  /* background-color: rgb(23 25 35 / 70%); */
  background-color: #171A24;
  position: fixed;
  width: 100%;

  z-index: 999;
`;

const StyledTopBarInner = styled.div`
      align-items: center;
      display: flex;
      justify-content: space-between;
      max-width: ${(props) => props.theme.siteWidth}px;
      width: 100%;
      height: 60px;
      /* background-color: rgb(23 25 35 / 70%); */
      background-color: #171A24;

    @media screen and (max-width:768px){
      display: flex;
      flex-wrap: nowrap;
      width:100%;
      justify-content: space-between;
    }

    @media screen and (max-width:356px){
      flex-wrap:wrap;
    }
   
`;
const StyledNavWrapper = styled.div`
@media screen and (min-width:1024px) {
     display: flex;
     flex: 1;
    justify-content: flex-end;
}
  
`;

const StyledAccountButtonWrapper = styled.div`
align-items:center;

    @media screen and (min-width:1024px) {
        align-items: center;
        display: flex;
        justify-content: flex-end;
    }

   @media screen and (max-width: 1000px) and (min-width:500px) {
      display:flex;
      flex-direction:row;
      justify-content:flex-start;
        margin-top: 8px;
        flex: auto;
        background: #171923;
        position:relative;
        margin-right:10px;
   }

   @media screen and (max-width: 768px) {
      width:100%;
      position:relative;
      display:flex;
      flex-direction:row;
      justify-content:space-between;
      background-color:#171A24;
 }
  
`;

const One = styled.div`
    display:flex;
    flex-direction:row;
    background-color:#171A24;
    width:80%;
    @media screen and (max-width:768px) {
        justify-content: flex-start;
        flex-basis: 25%;
        flex-grow:1;
    }

    @media screen and (max-width: 356px) {
        display: flex;
        justify-content: center;
        flex-grow:1; 
    }


`

const Two = styled.div`
      display:flex;
      flex-direction:row;
      justify-content:flex-end;
      background-color:#171A24;
      align-items: center;
      @media screen and (max-width:768px) {
          flex-grow:1;
          justify-content: space-between;
          padding: 10px 5px 10px 0px;
          flex-basis: 270px;
      }
      @media screen and (max-width:320px) {

      }
`
const Notice = styled.div`
        width:100%;
        position:absolute;
        z-index:100;
        overflow:hidden;
        top:60px;

        @media (max-width:356px){
          top: 100px;

        }
        
`



export default TopBar;
