import React, { useEffect } from "react";
import styled from "styled-components";
import { useWallet } from "use-wallet";

import metamaskLogo from "../../assets/img/metamask-fox.svg";
import walletConnectLogo from "../../assets/img/wallet-connect.svg";

import Button from "../Button";
import Modal, { ModalProps } from "../Modal";
import ModalActions from "../ModalActions";
import ModalContent from "../ModalContent";
import ModalTitle from "../ModalTitle";
import Spacer from "../Spacer";

import WalletCard from "./components/WalletCard";

import { useTranslation } from "react-i18next";
import {
  connectMetaMaskReset,
  connectToMetaMask,
} from "../../libs/autoconnect";

const WalletProviderModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { account, connect } = useWallet();
  const { t } = useTranslation();

  useEffect(() => {
    if (account) {
      onDismiss();
    }
  }, [account, onDismiss]);

  return (
    <Modal>
      <ModalTitle text={t("selectAWalletProvider")} />

      <ModalContent>
        <StyledWalletsWrapper>
          <StyledWalletCard>
            <WalletCard
              icon={
                <img src={metamaskLogo} style={{ height: 32 }} alt="icon" />
              }
              onConnect={() =>
                connect("injected")
                  .then(() => connectToMetaMask())
                  .catch(() => connectMetaMaskReset())
              }
              title="Metamask"
            />
          </StyledWalletCard>
          <Spacer size="sm" />
          <StyledWalletCard>
            <WalletCard
              icon={
                <img
                  src={walletConnectLogo}
                  style={{ height: 24 }}
                  alt="icon"
                />
              }
              onConnect={() => connect("walletconnect")}
              title="WalletConnect"
            />
          </StyledWalletCard>
        </StyledWalletsWrapper>
      </ModalContent>

      <ModalActions>
        <Button text={t("cancel")} variant="secondary" onClick={onDismiss} />
      </ModalActions>
    </Modal>
  );
};

const StyledWalletsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    flex-direction: column;
    flex-wrap: none;
    > div {
      flex: 1;
    }
  }
`;

const StyledWalletCard = styled.div`
  flex-basis: calc(50% - ${(props) => props.theme.spacing[2]}px);
  flex: 1;
`

export default WalletProviderModal;
