import React from 'react';
import styled from 'styled-components';

export default function Note() {
    return (
        <>
            <Box>
                <ListTs><ListTitle>公告</ListTitle></ListTs>
                {/** 手机端样式 */}
                <List contentEditable={true}>
                    <Listcon></Listcon>
                    <Listcon>
                        <Icon></Icon>
                        <Time>2020年12月23日</Time>
                        <Texts>机枪池收益分为两部分组成，HUSD和EDC奖励，HUSD收益10分钟左右更新一次，EDC奖励将于1月30日统一分发</Texts>
                    </Listcon>
                    <Listcon>
                        <Icon></Icon>
                        <Time>2020年12月23日</Time>
                        <Texts>机枪池收益分为两部分组成，HUSD和EDC奖励，HUSD收益10分钟左右更新一次，EDC奖励将于1月30日统一分发</Texts>
                    </Listcon>
                    <Listcon>
                        <Icon></Icon>
                        <Time>2020年12月23日</Time>
                        <Texts>机枪池收益分为两部分组成，HUSD和EDC奖励，HUSD收益10分钟左右更新一次，EDC奖励将于1月30日统一分发</Texts>
                    </Listcon>
                    <Listcon>
                        <Icon></Icon>
                        <Time>2020年12月23日</Time>
                        <Texts>机枪池收益分为两部分组成，HUSD和EDC奖励，HUSD收益10分钟左右更新一次，EDC奖励将于1月30日统一分发</Texts>
                    </Listcon>
                    <Listcon>
                        <Icon></Icon>
                        <Time>2020年12月23日</Time>
                        <Texts>机枪池收益分为两部分组成，HUSD和EDC奖励，HUSD收益10分钟左右更新一次，EDC奖励将于1月30日统一分发</Texts>
                    </Listcon>
                    <Listcon>
                        <Icon></Icon>
                        <Time>2020年12月23日</Time>
                        <Texts>机枪池收益分为两部分组成，HUSD和EDC奖励，HUSD收益10分钟左右更新一次，EDC奖励将于1月30日统一分发</Texts>
                    </Listcon>
                    <Listcon></Listcon>
                </List>

                {/** 电脑端样式 */}
                <Boxss>
                    <Listm contentEditable={true}>
                        <Timem>2020年12月23日</Timem>
                        <Listconm>
                            <Iconm></Iconm>
                            <Textsm>机枪池收益分为两部分组成，HUSD和EDC奖励，HUSD收益10分钟左右更新一次，EDC奖励将于1月30日统一分发</Textsm>
                        </Listconm>
                    </Listm>
                    <Listm contentEditable={true}>
                        <Timem>2020年12月23日</Timem>
                        <Listconm>
                            <Iconm></Iconm>
                            <Textsm>机枪池收益分为两部分组成，HUSD和EDC奖励，HUSD收益10分钟左右更新一次，EDC奖励将于1月30日统一分发</Textsm>
                        </Listconm>
                    </Listm>
                    <Listm contentEditable={true}>
                        <Timem>2020年12月23日</Timem>
                        <Listconm>
                            <Iconm></Iconm>
                            <Textsm>机枪池收益分为两部分组成，HUSD和EDC奖励，HUSD收益10分钟左右更新一次，EDC奖励将于1月30日统一分发</Textsm>
                        </Listconm>
                    </Listm>
                    <Listm contentEditable={true}>
                        <Timem>2020年12月23日</Timem>
                        <Listconm>
                            <Iconm></Iconm>
                            <Textsm>机枪池收益分为两部分组成，HUSD和EDC奖励，HUSD收益10分钟左右更新一次，EDC奖励将于1月30日统一分发</Textsm>
                        </Listconm>
                    </Listm>
                    <Listm contentEditable={true}>
                        <Timem>2020年12月23日</Timem>
                        <Listconm>
                            <Iconm></Iconm>
                            <Textsm>机枪池收益分为两部分组成，HUSD和EDC奖励，HUSD收益10分钟左右更新一次，EDC奖励将于1月30日统一分发</Textsm>
                        </Listconm>
                    </Listm>
                    <Listm contentEditable={true}>
                        <Timem>2020年12月23日</Timem>
                        <Listconm>
                            <Iconm></Iconm>
                            <Textsm>机枪池收益分为两部分组成，HUSD和EDC奖励，HUSD收益10分钟左右更新一次，EDC奖励将于1月30日统一分发</Textsm>
                        </Listconm>
                    </Listm>
                </Boxss>
            </Box>
        </>
    )
}


const Box = styled.div`
    width: 100%;
    height:100%;
    background:#1E2026;
    position: absolute;
    margin: 60px auto 0;
`
const Boxss = styled.div`
   @media screen and (min-width:1024px) {
          width:100%;
        margin-top:90px;
   }
    @media screen and (min-width:500px) and (max-width:1000px) {
          width:100%;
          margin-top:90px;
          display:none;
   }
   @media screen and (max-width:500px) {
          width:100%;
          margin-top:90px;
           display:none;
   }
`
const Listm = styled.div`
  @media screen and (min-width:1024px) {
        width:80%;
        position:relative;
        margin:0 auto;
        display:flex;
        flex-direction:row;
        align-items:flex-start;
  }
   @media screen and (min-width:500px) and (max-width:960px) {
       width:80%;
       position:relative;
       margin:30px auto 0;
       padding:10px;
       display:flex;
       flex-direction:column;
       align-items:flex-start;
   }
   @media screen and (max-width:500px) {
         width:80%;
        position:relative;
        margin:0 auto;
        display:flex;
        flex-direction:column;
        align-items:flex-start;
   }
`
const Iconm = styled.div`
      width:8px;
      height:8px;
      background: #EDB605;
      border-radius: 2px;
      margin: 0 0 0 -5px;
`
const Listconm = styled.div`
   @media screen and (max-width:1000px){
        display:flex;
        flex-direction:column;
        justify-content:flex-start;
        width:95%;
        border-left:1px solid #3F3D3D;
   }
   @media screen and (min-width:1024px) {
         width:80%;
        border-left:1px solid #3F3D3D;
        padding:20px 0;
   }
`
const Timem = styled.div`
    @media screen and (min-width:1024px) {
             width:150px;
            font-family: SourceHanSansCN-Regular;
            font-size: 14px;
            color: #6C6B66;
            letter-spacing: 0.22px;
            line-height:50px;
    }
   @media screen and (max-width:960px) and (min-width:500px) {
       width:100%;
        font-family: SourceHanSansCN-Regular;
        font-size: 14px;
        color: #6C6B66;
        letter-spacing: 0.22px;
        margin: 0 0 0 36px;
   }
    @media screen and (max-width:500px) {
             width:100%;
            font-family: SourceHanSansCN-Regular;
            font-size: 14px;
            color: #6C6B66;
            letter-spacing: 0.22px;
            line-height:50px;
    }
`
const Textsm = styled.div`
    @media screen and (max-width:1000px) {
         width:100%;
        font-family: SourceHanSansCN-Normal;
        font-size: 13px;
        color: #EAE8E0;
        letter-spacing: 0.4px;
        line-height: 19px;
        margin: 10px 0 0 36px;
    }

    @media screen and (min-width:1024px) {
        width:100%;
        font-family: SourceHanSansCN-Normal;
        font-size: 13px;
        color: #EAE8E0;
        letter-spacing: 0.4px;
        line-height: 19px;
        margin: -12px 0 0 36px;
    }
`
const ListTs = styled.div`
        width:100%;
        height:44px;
        position:fixed;
        z-index:999;
`
const ListTitle = styled.div`  
    @media screen and (min-width:1024px){
       width: 80%;
       height: 44px;
       background: #373941;
       color: #fff;
       text-align: left;
       padding-left: 10px;
       position: relative;
       margin: -3px auto;
       z-index: 999;
       line-height: 40px;
    }

    @media screen and (min-width:500px) and (max-width:1000px) {
      width: 100%;
      height: 44px;
      background: #373941;
      color: #fff;
      text-align: left;
      padding-left: 10px;
      position: fixed;
      margin: -3px auto;
      z-index: 999;
      line-height: 40px;
   }
 
    @media screen and (max-width:500px) {
      width: 100%;
      height: 44px;
      background: #373941;
      color: #fff;
      text-align: left;
      padding-left: 10px;
      position: fixed;
      margin: 36px auto;
      z-index: 999;
      line-height: 40px;
   }
`
//手机端
const List = styled.div`
  @media screen and (min-width:1024px) {
        width:1000px;
        position:relative;
        margin:0 auto;
        padding:10px;
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        display:none;
  }

   @media screen and (min-width:500px) and (max-width:1000px) {
       width:80%;
       position:relative;
       margin:30px auto 0;
       padding:10px;
       display:flex;
       flex-direction:column;
       align-items:flex-start;
   }
 
   @media screen and (max-width:500px) {
         width:80%;
        position:relative;
        margin:70px auto 0;
        padding:10px;
        display:flex;
        flex-direction:column;
        align-items:flex-start;
   }
`
const Icon = styled.div`
      width:8px;
      height:8px;
      background: #EDB605;
      border-radius: 2px;
      margin: 0 0 0 -5px;
`
const Listcon = styled.div`
      display:flex;
      flex-direction:column;
      justify-content:flex-start;
      width:95%;
      border-left:1px solid #3F3D3D;
      padding:20px 0;
`
const Time = styled.div`
       width:100%;
       font-family: SourceHanSansCN-Regular;
        font-size: 14px;
        color: #6C6B66;
        letter-spacing: 0.22px;
        margin: -13px 0 0 36px;
`
const Texts = styled.div`
        width:100%;
        font-family: SourceHanSansCN-Normal;
        font-size: 13px;
        color: #EAE8E0;
        letter-spacing: 0.4px;
        line-height: 19px;
        margin: 10px 0 0 36px;
`