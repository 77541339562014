import React, { useMemo, useState } from "react";
import styled from "styled-components";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import CardContent from "../../../components/CardContent";
import Label from "../../../components/Label";
import Value from "../../../components/Value";
import { getBalanceNumber } from "../../../utils/formatBalance";
import CardIcon from "./CardIcon";
import FarmIcon from "../../../assets/img/farm-icon.png";


import { useTranslation } from "react-i18next";
import { Farm } from "../../../contexts/Farms";
import { isLPVault, isMdexLP, isReinvest2 } from "../../../contexts/Farms/types";
import { useTypedSelector } from "../../../libs/state/store";
import BigNumber from "bignumber.js";
import { useCardAction } from "../../../hooks/useCardAction";
interface HarvestProps {
  pid: number;
  farm: Farm;
}
const StyledValueFieldRoot = styled.div`
  min-width: 223px;
  margin-top: 3px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
const StyledValueLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ValueField: React.FC<{
  symbol: string;
  value: number;
  decimals: number;
}> = ({ symbol, value }) => {
  return (
    <StyledValueFieldRoot>
      <StyledValueLine>
        <Value decimals={6} value={value} />
        <Label text={`${symbol + ((symbol.indexOf("/") === -1 && symbol.indexOf("HBO") === -1) ? " 待空投" : " 已自动复投")}`} />
      </StyledValueLine>
    </StyledValueFieldRoot>
  );
};

const Harvest: React.FC<HarvestProps> = ({ pid, farm }) => {
  const earned1 = useTypedSelector(e => e.PoolReducer?.[farm.pid]?.earned1 ?? new BigNumber(0))
  const earned2 = useTypedSelector(e => e.PoolReducer?.[farm.pid]?.earned2 ?? new BigNumber(0))

  const [pendingTx, setPendingTx] = useState(false);

  const { t } = useTranslation();

  const { onHarvest } = useCardAction(farm)
  const isLP = isLPVault(farm);
  const isLP2 = isReinvest2(farm);
  const _isMdexLP = isMdexLP(farm)
  const value = getBalanceNumber(earned1, farm.earnDecimal);


  const FormatedValue1 = useMemo(() => {
    if (isLPVault(farm) || isLP2)
      return getBalanceNumber(earned1, 9);
    else
      return getBalanceNumber(earned1, farm.earnDecimal);
  }, [earned1, farm])

  const FormatedValue2 = useMemo(() => {
    if (_isMdexLP)
      return getBalanceNumber(earned2, 18);
    return getBalanceNumber(earned2, farm.earnDecimal);
  }, [_isMdexLP, earned2, farm.earnDecimal])


  return (
    <Card>
      <CardContent>
        <StyledCardContentInner>
          <StyledCardHeader>
            <CardIcon>
              <StyledImageIcon src={FarmIcon} alt="icon" />
            </CardIcon>
            <>
              {isLP ? (
                <ValueField
                  symbol={"EDC"}
                  decimals={9}
                  value={FormatedValue1}
                />
              ) : farm.name === "MDEX EDC-USDT LP" ? (
                <>
                  <Value value={FormatedValue1} decimals={6} />
                  <Label text={`${"EDC "} ${t("earned")}`} />
                  <Value value={FormatedValue2} decimals={6} />
                  <Label text={"HT 待收获"} />
                </>
              ) : (
                <>
                  <Value value={value} decimals={6} />
                  <Label text={`${farm.earnToken} ${ isLP2 ? t("airdrop") : t("earned")}`} />
                </>
              )}

            </>
          </StyledCardHeader>

          <StyledCardActions>
            <Button
              disabled={(!earned1.toNumber() || pendingTx) && (!isLP) && (!isLP2)}
              text={pendingTx ? "Collecting EDC" : t("tokensAndEarn")}
              onClick={async () => {
                setPendingTx(true);
                await onHarvest()
                setPendingTx(false);
              }}
            />
          </StyledCardActions>
        </StyledCardContentInner>
      </CardContent>
    </Card>
  );
};

const StyledCardHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;
const StyledCardActions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing[6]}px;
  width: 100%;
`;

// const StyledSpacer = styled.div`
//   height: ${(props) => props.theme.spacing[4]}px;
//   width: ${(props) => props.theme.spacing[4]}px;
// `

const StyledCardContentInner = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledImageIcon = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

export default Harvest;
