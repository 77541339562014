import React from 'react'
import styled from 'styled-components'
import { Switch } from 'antd';
import { useDispatch } from 'react-redux';
import { ActSetState } from '../../libs/state/global';
import { useTypedSelector } from '../../libs/state/store';
import { useTranslation } from 'react-i18next';


const Root = styled.div`
    width: 900px;
    display: flex;
    justify-content: space-between;
    padding-right: 16px;
    box-sizing: border-box;
    background: #202328;
    
    @media (min-width: 768px) {
        margin-bottom: 29px;
    }
    @media (max-width: 768px) {
        width: calc( 100% - 8px );
        padding:0;
        flex-wrap:wrap;
        margin-top: calc( 36px + 8px );
        margin-bottom: 29px;
        margin-left: 8px;
    }
    @media (max-width:356px){
        margin-top: calc( 136px + 8px );

    }


`
const TVLBox = styled.div`
    @media (max-width: 768px) {
        flex-basis: 100%;
        margin-bottom: 20px;
        padding-top: 5px;
    }

    background: linear-gradient(270deg, rgba(24, 26, 30, 0.0001) 0%, #2F3237 100%);
    height: 100%;
    padding-left: 20px;
    padding-top: 10px;
    font-family: Source Han Sans CN;
    font-style: normal;
    font-weight: normal;
    text-align: justify;
    display: flex;
    flex-direction:column;
    
    .title{
        font-size: 26px;
        line-height: 39px;
        text-align: justify;
        letter-spacing: 0.0233333px;
        color: #AFAEAA;
    }
    .value{
        white-space:nowrap;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 33px;
        letter-spacing: 2px;
        color: #F9C41C;
    }
`
const SwitchBox = styled.div`
    position: relative;
    flex-grow:1;

    @media (max-width: 768px) {
        flex-basis: 100%;
        min-height: 35px;
        display:flex;
        justify-content:space-between;
        flex-wrap:nowrap;
        margin:0 10px;
        align-items:center;
        
    }
`
const ButtonBox = styled.div`
    position: absolute;
    @media (max-width: 768px) {
        position: static;
        height: 35px;
    }
    display: flex;
    bottom: 0;
    right:0;
    background: rgba(0, 0, 0, 0.186954);
    border: 1px solid #979797;
    box-sizing: border-box;
    border-radius: 4px;
`
const SwitchButton = styled.div<{ active?: boolean }>`
    width: 146px;
    height: 50px;
    color:white;
    display:flex;
    justify-content:center;
    align-items:center;
    border-radius: 4px;
    flex-grow:1; 
    cursor: pointer;
    @media (max-width: 768px) {
        width: 90px;
        height: 35px;
    }
    background: ${({ active }) => active ? ` #F9C41C` : `auto`};
    :hover {
        background: #F9C41C;
    }
`
const StyledSwitchBox = styled.div`
    position:absolute;
    bottom:0;
    left: 64px;
    color:white;
    margin: 8px;
    transform: scale(1.5);
    span{
        margin: 0 0.25rem;
    }

    @media (max-width: 768px) {
        position:static;
        height: 35px;
        transform: none;
        display: flex;
        text-align:center;
        align-items:center;
    }
`

const PoolSwitch: React.FC<{
    TVL: string
    value: string
    onChange: (v: string) => void
}> = ({
    TVL,
    value,
    onChange
}) => {
        const dispatch = useDispatch()
        const stakedOnly = useTypedSelector(e => e.GlobalReducers.stakedOnly)
        const loading = useTypedSelector(e => e.GlobalReducers.loadingPoolStake)
        const { t } = useTranslation()
        return (

            <Root>
                <TVLBox>
                    <span className={"title"}>Total Value Locked</span>
                    <span className={"value"}>{TVL}</span>
                </TVLBox>
                <SwitchBox>
                    <StyledSwitchBox>
                        <Switch
                            disabled={loading}
                            checked={stakedOnly}
                            title="staked only"

                            loading={loading}
                            onChange={(e) => dispatch(ActSetState({ stakedOnly: e }))}

                            style={{
                                background: stakedOnly ? "#FFDF79" : "#6D664D"
                            }}

                        />

                        <span>{t("StakedOnly")}</span>

                    </StyledSwitchBox>

                    <ButtonBox>
                        <SwitchButton onClick={() => onChange("active")} active={value === 'active'}>{t("Active")}</SwitchButton>
                        <SwitchButton onClick={() => onChange("inactive")} active={value === "inactive"}>{t("Inactive")}</SwitchButton>
                    </ButtonBox>
                </SwitchBox>
            </Root>
        )
    }
export default PoolSwitch