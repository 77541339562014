const NavData = [
    {
        "key": "index",
        "route": "/index"
    },
    {
        "key": "v3",
        "route": "/v3"
    },
    {
        "key": "reinvest2",
        "route": "/reinvest2"
    },
    {
        "key": "lpvault",
        "route": "/lpvault"
    },
    {
        "key": "staking",
        "route": "/farms"
    },
    {
        "key": "vault",
        "route": "/vault"
    },
    {
        "key": "Swap",
        "route": "https://ht.mdex.com/#/swap?inputCurrency=0xa71edc38d189767582c38a3145b5873052c3e47a&outputCurrency=0x68a0a1fef18dfcc422db8be6f0f486dea1999edc",
        "out": true
    }
]
export default NavData
